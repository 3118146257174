<ng-container *ngIf='!modelIsValid()'>
  No prices found.
</ng-container>
<ng-container *ngIf='modelIsValid()'>
  <app-gantt-zoom></app-gantt-zoom>
</ng-container>
<div class="icon-container">
  <div class="icon-text">
      <i class="pi pi-shopping-cart"></i>
      <span>Local price set by stores</span>
  </div>
  <div class="icon-text">
      <i class="pi pi-globe"></i>
      <span>Web specific price</span>
  </div>
  <div class="icon-text">
      <i class="pi pi-lock"></i>
      <span>Price that can't be changed</span>
  </div>
  <div class="icon-text">
      <i class="pi pi-flag"></i>
      <span>SAP price</span>
  </div>
  <div class="icon-text">
      <i class="pi pi-sort-alt"></i>
      <span>Dynamic pricing price</span>
  </div>
</div>
<app-settings-menu [element]="gantt"></app-settings-menu>

<div #gantt class='gantt-chart'></div>

<pre *ngIf="jsonDisplay$ | async" [innerHTML]="(timelinesToDisplay | currentModel) | prettyJson" >
</pre>
