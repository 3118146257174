import {Component, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {Category, GeoParametersCategories, Parameter} from '../../model/parameters';
import {Observable} from 'rxjs';
import {ParametersState} from '../../state/action/state';
import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-parameter',
  styleUrls: ['parameter.scss'],
  templateUrl: './parameter.component.html',
})
export class ParameterComponent implements OnInit {

  @Select(ParametersState)
  private _parameters$: Observable<GeoParametersCategories>

  private _treeParameters: TreeNode[];

  constructor() {}

  ngOnInit(): void {
    this._treeParameters = [];
    this._parameters$.subscribe(parameters => this.toTreeParameterModel(parameters))
  }

  private toTreeParameterModel(parameters?: GeoParametersCategories) {
    if (!!parameters) {
      const parametersSort = JSON.parse(JSON.stringify(parameters));
      parametersSort.categories_parameters.sort((a: any, b: any) => a.category.name.localeCompare(b.category.name));

      parametersSort.categories_parameters.forEach((categoryParam: any) => {
        categoryParam.parameters.sort((a: any, b: any) => a.name.localeCompare(b.name));
      });

      this._treeParameters = parametersSort?.categories_parameters?.map(
        category_parameters => {
          return {
            label: this.toCategoryLabel(category_parameters.category),
            tooltip: category_parameters.category.description,
            children: category_parameters.parameters.map(parameter => this.toTreeParameter(parameter))
          };
        });
    }

  }

  private toCategoryLabel(category: Category) {
    if (!!category.explanation) {
      return `${category.name} = ${category.explanation}`
    }
    return category.name;
  }

  private toTreeParameter(parameter: Parameter) {
    return {
      label: this.toParameterLabel(parameter),
      tooltip: parameter.description
    };
  }

  private toParameterLabel(parameter: Parameter): string {
    return `${parameter.name} = ${parameter.value}`;
  }

  get treeParameters(): TreeNode[] {
    return this._treeParameters;
  }
}
