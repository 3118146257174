import { Component, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.js';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { QueryContextState } from '../../state/action/state';
import { QueryContext } from '../../state/model/entity-geo-area';
import { ChangeDisplayExplain, ChangeDisplayJSON } from '../../state/actions';
import { ContextMenu } from 'primeng/contextmenu';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
})
export class SettingsMenuComponent implements OnInit, OnDestroy {
  @Input() element: HTMLElement;
  @Input() isLeftClickable: boolean = false;

  @ViewChild('contextMenu', { static: true })
  contextMenuContainer!: ContextMenu;

  showParameter = false;
  showGeoArea = false;
  showItemsHierarchy = false;
  menuItems$: Observable<MenuItem[]>;
  destroy$: Subject<void>;
  constructor(private store: Store, private renderer: Renderer2) {}
  ngOnInit() {
    this.destroy$ = new Subject();
    this.displayMenu();
    this.manageLeftClick();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private manageLeftClick() {
    if (this.isLeftClickable) {
      this.renderer.listen(this.element, 'click', event => {
        event.preventDefault();
        event.stopPropagation();
        this.contextMenuContainer.show(event);
      });
    }
  }
  private displayMenu() {
    this.menuItems$ = this.store.select(QueryContextState)
      .pipe(
        takeUntil(this.destroy$),
        map(context => this.generateMenuItems(context))
      );
  }

  generateMenuItems(context?: QueryContext): MenuItem[] {
    const baseItems = [
      {
        label: 'Explain',
        icon: 'pi pi-refresh',
        command: () => this.recomputeModel()
      }, {
        label: 'Parameters',
        icon: 'pi pi-fw pi-cog',
        command: () => this.showParameter = !this.showParameter
      }, {
        label: 'Geographic area',
        icon: 'pi pi-fw pi-home',
        command: () => this.showGeoArea = !this.showGeoArea
      }, {
        label: 'Show debug JSON',
        icon: 'pi pi-fw pi-search-plus',
        command: () => this.changeDisplayJSON()
      },
      {
        label: 'Show items hierarchy',
        icon: 'pi pi-fw pi-share-alt',
        command: () => this.showItemsHierarchy = !this.showItemsHierarchy
      }
    ];
    return context?.user?.roles?.includes('ADMIN') ? baseItems : baseItems.filter(item => (item.label === 'Geographic area') || (item.label === 'Parameters') || (item.label === 'Show items hierarchy'));
  }

  private changeDisplayJSON() {
     this.store.dispatch(new ChangeDisplayJSON())
  }

  private recomputeModel() {
     this.store.dispatch(new ChangeDisplayExplain());
  }
}
