import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Actions, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { Engine } from 'src/app/model/engine';
import { Type } from 'src/app/model/type';
import { EntityId, isEntityIdentifier, isPartnerId, isSecondLifeId } from 'src/app/model/entity-id';
import { GeographicArea, toTierConcat } from 'src/app/model/geographic-area';
import { RpcService } from '../../api/rpc.service';
import {
  ChangeDisplayExplain,
  ChangeDisplayJSON,
  EngineSelect,
  EntityGeoAreaSelect,
  EntityIdNotFound,
  EntityIdSelect,
  GeoAreaSelect,
  ParametersDefined,
  PriceIdSelect,
  PriceIdsSelect,
  ResetDisplayExplain,
  ResetParameters,
  selectTimelines,
  SkuSelect,
  TimelinesRecomputeModel,
  TimelinesToDisplayPaginate,
  TimelinesToDisplayReset,
  TimelinesToDisplaySelect,
  TypeSelect,
  UserSelect
} from '../actions';
import { QueryContext } from '../model/entity-geo-area';
import { currentModel, timelines, TimelinesToDisplay } from '../model/timelines-to-display';
import { GeoAreaFoundState } from './geo-area';
import { NotificationsService } from 'src/app/api/notifications.service';
import { NrtModel } from '../../model/nrt-model';
import { JsonDisplay } from '../model/json-display';
import { GeoParametersCategories } from '../../model/parameters';
import { ExplainDisplay } from '../model/explain-display';

@Injectable()
@State<QueryContext>({ name: 'entityGeoArea' })
export class QueryContextState {

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location) {
  }

  @Action([EntityIdSelect, GeoAreaSelect, EngineSelect, UserSelect, TypeSelect])
  select(ctx: StateContext<QueryContext>, result: QueryContext) {
    const entityGeoArea = { ...ctx.getState(), ...result };
    ctx.setState(entityGeoArea);
    if (isEntityGeoAreaValid(entityGeoArea)) {
      this.updateLocation(entityGeoArea.entityId, entityGeoArea.geoArea, entityGeoArea.engine, entityGeoArea.type);
      this.store.dispatch(new EntityGeoAreaSelect(entityGeoArea));
      this.store.dispatch(new ResetDisplayExplain());
    } else {
      this.store.dispatch(new TimelinesToDisplayReset());
    }

    function isEntityGeoAreaValid(entityGeoArea: QueryContext) {
      return isGeoAreaValid(entityGeoArea.geoArea) && entityGeoArea.entityId &&
        (isMarketPlace(entityGeoArea) || isRetail(entityGeoArea) || isSecondLife(entityGeoArea));
    }

    function isMarketPlace(entityGeoArea: QueryContext): boolean {
      return entityGeoArea.engine === Engine.Marketplace && isPartnerId(entityGeoArea.entityId);
    }

    function isRetail(entityGeoArea: QueryContext): boolean {
      return entityGeoArea.engine === Engine.Retail && isEntityIdentifier(entityGeoArea.entityId) && entityGeoArea.entityId.code !== 0;
    }
    function isSecondLife(entityGeoArea: QueryContext) {
      return entityGeoArea.engine === Engine.Secondlife && isSecondLifeId(entityGeoArea.entityId);
    }
  }

  updateLocation(model: EntityId, geo: GeographicArea, engine: Engine, type:Type) {
    const queryParams = {
      tiers_concat: this.activatedRoute.params['tiers_concat'],
      entity_code: this.activatedRoute.params['entity_code'],
      engine: this.activatedRoute.params['engine'],
      type: this.activatedRoute.params['type']
    };

    let newQueryParams = {};

    if (geo) {
      newQueryParams['tiers_concat'] = toTierConcat(geo);
    }
    if (isPartnerId(model) && engine === Engine.Marketplace) {
      newQueryParams['entity_code'] = model.partner_id;
    } else  if (isSecondLifeId(model) && engine === Engine.Secondlife) {
      newQueryParams['entity_code'] = model.second_life_id;
    }else if (isEntityIdentifier(model) && engine === Engine.Retail) {
      newQueryParams['entity_code'] = model.code;
    }
    if (engine) {
      newQueryParams['engine'] = engine.toString();
    }
    if (type && engine != Engine.Marketplace) {
      newQueryParams['type'] = type.toString();
    }
    if (queryParams !== newQueryParams) {
      const url = this.router.createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: newQueryParams
      }).toString();
      this.location.replaceState(url);
    }
  }
}

@Injectable()
@State<boolean>({ name: 'entityIdNotFound', defaults: false })
export class EntityIdNotFoundState {
  @Action(EntityIdNotFound)
  updateState(ctx: StateContext<boolean>, result: EntityIdNotFound) {
    ctx.setState(result.found);
  }
}

@Injectable()
@State<GeoParametersCategories>({ name: 'parameters', defaults: undefined })
export class ParametersState {
  constructor(private rpcService: RpcService, private store: Store) {}

  @Action(GeoAreaSelect)
  defineParameters(ctx: StateContext<any>, geoAreaSelected: GeoAreaSelect) {
    if (isGeoAreaValid(geoAreaSelected.geoArea)) {
      this.rpcService.fetchParametersForGeoArea(geoAreaSelected.geoArea).subscribe(parameters => {
        this.store.dispatch(new ParametersDefined(parameters))
      });
    } else {
      this.store.dispatch(new ResetParameters())
    }
  }

  @Action(ParametersDefined)
  parametersDefined(ctx: StateContext<any>, parametersDefinedEvent: ParametersDefined) {
    ctx.setState(parametersDefinedEvent.parameters);
  }

  @Action(ResetParameters)
  resetParameters(ctx: StateContext<any>, resetParametersEvent: ResetParameters) {
    ctx.setState(undefined);
  }
}
@Injectable()
@State<TimelinesToDisplay>({ name: 'timelines' })
export class TimelinesToDisplayState {
  constructor(private rpcService: RpcService,
              private actions$: Actions,
              private store: Store,
              private notificationService: NotificationsService) {
    actions$.pipe(
      ofActionDispatched(EntityGeoAreaSelect),
      map((result: EntityGeoAreaSelect) => result.entityGeoArea),
      debounceTime(800),
      mergeMap(entityGeo => this.rpcService.fetchEngine(entityGeo)))
      .subscribe(nrtModels => this.manageSelectTimelines(nrtModels).map(value => this.store.dispatch(value)))
  }

  @Action(TimelinesToDisplaySelect)
  updateTimelines(ctx: StateContext<TimelinesToDisplay>, result: TimelinesToDisplaySelect) {
    ctx.setState(result.timelines);
  }

  @Action(TimelinesToDisplayReset)
  resetTimelines(ctx: StateContext<TimelinesToDisplay>, resetEvent: TimelinesToDisplayReset) {
    ctx.setState(undefined);
  }

  @Action(TimelinesToDisplayPaginate)
  updatePagination(ctx: StateContext<TimelinesToDisplay>, pagination: TimelinesToDisplayPaginate) {
    const maxLength = ctx.getState()?.nrtModels?.length ?? 0;

    ctx.setState({
      ...ctx.getState(),
      displayedModel: pagination.wantedPage % maxLength
    });
  }

  @Action(TimelinesRecomputeModel)
  recomputeTimelinesModel(ctx: StateContext<TimelinesToDisplay>, recompute: TimelinesRecomputeModel) {
    this.rpcService.computeEngine(currentModel(ctx.getState()).input, this.store.selectSnapshot(QueryContextState))
      .subscribe(nrtModels => this.store.dispatch(selectTimelines(nrtModels)));
  }
  
  manageSelectTimelines(nrtModels: NrtModel[]): any[] {
    if (!!nrtModels) {
      const timelinesToDisplay: TimelinesToDisplay = timelines(nrtModels);
      const nrtModel = currentModel(timelinesToDisplay);
      if (this.hasRequestedPrices(nrtModel)) {
        return [
          new TimelinesToDisplaySelect(timelinesToDisplay),
        ]
      } else {
        this.notificationService.info('No requested prices', 'SAP did not send us any prices for this model and its items.');
        return [new TimelinesToDisplayReset()]
      }
    }
    return [];
  }

  private hasRequestedPrices(nrtModel: NrtModel) {
    return nrtModel.input.product_process_units.flatMap(ppu => ppu.prices_to_save).length !== 0;
  }
}
@Injectable()
@State<Engine>({ name: 'engine', defaults: Engine.Retail })
export class EngineState {
  constructor(private store: Store) {}

  @Action(EngineSelect)
  updateState(ctx: StateContext<Engine>, incoming: EngineSelect) {
    if (incoming.engine === Engine.Marketplace && this.hasInvalidMarketPlaceType()) {
      this.store.dispatch(new TypeSelect(Type.PartnerId))
    }
    ctx.setState(incoming.engine);
  }

   hasInvalidMarketPlaceType() {
    const type = this.store.selectSnapshot(TypeState);
    return !type || type === Type.Model || type === Type.Item;
  }
}

@Injectable()
@State<Type>({ name: 'type', defaults: Type.Model })
export class TypeState {
  constructor(){}

  @Action(TypeSelect)
  updateState(ctx: StateContext<Type>, incoming: TypeSelect) {
    ctx.setState(incoming.type);
  }
}

@Injectable()
@State<ExplainDisplay>({ name: 'explainDisplay', defaults: false})
export class ExplainDisplayState {
  constructor(private store: Store) {}

  @Action(ChangeDisplayExplain)
  changeExplain(ctx: StateContext<ExplainDisplay>, event: ChangeDisplayExplain) {
    ctx.setState(!ctx.getState());
    if (ctx.getState())  {
      this.store.dispatch(new TimelinesRecomputeModel());
    } else {
      this.store.dispatch(new EntityGeoAreaSelect(this.store.selectSnapshot(QueryContextState)));
    }
  }
  @Action(ResetDisplayExplain)
  resetExplain(ctx: StateContext<ExplainDisplay>, event: ChangeDisplayExplain) {
    ctx.setState(false);
  }
}

@Injectable()
@State<JsonDisplay>({ name: 'jsonDisplay', defaults: false})
export class JSONDisplayState {
  @Action(ChangeDisplayJSON)
  updateState(ctx: StateContext<JsonDisplay>, event: ChangeDisplayJSON) {
    ctx.setState(!ctx.getState());
  }
}
@Injectable()
@State<string>({ name: 'sku' })
export class SkuState {

  @Action(SkuSelect)
  updateState(ctx: StateContext<string>, incoming: SkuSelect) {
    ctx.setState(incoming.sku)
  }
}

@Injectable()
@State<string[]>({ name: 'priceIds' })
export class PricesIdsState {
  constructor(private rpcService: RpcService,
              private notificationsService: NotificationsService,
              private store: Store,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private location: Location) {
  }

  @Action([SkuSelect, GeoAreaSelect, TypeSelect])
  updatePriceidsState(ctx: StateContext<string[]>, incoming: any ) {
    const geoArea = incoming.geoArea != undefined ? incoming.geoArea : this.store.selectSnapshot(QueryContextState).geoArea;
    const type = this.store.selectSnapshot(TypeState);

    if (geoArea && type === Type.Sku) {
      const sku = incoming.sku != undefined ? incoming.sku : this.store.selectSnapshot(state => state.sku);
      if (!(sku instanceof Object)) {
        this.store.dispatch(new ResetDisplayExplain());
        this.updateSkuLocation(sku, geoArea);
        this.rpcService.fetchPriceAvailability(sku, toTierConcat(geoArea)).subscribe(
          res => {
            if (res.length == 0) {
              this.notificationsService.warn('No price id found', 'Either this sku is out of stock or there is no price id linked to this sku')
            } else {
              this.store.dispatch(new PriceIdsSelect(res));
              this.store.dispatch(new PriceIdSelect(res[0]));
            }
          });
      }
    }
  }

  @Action(PriceIdsSelect)
  updatePriceIdsState(ctx: StateContext<string[]>, incoming: PriceIdsSelect ) {
    ctx.setState(incoming.priceIds);
  }

  @Action(PriceIdSelect)
  updatePriceIdState(ctx: StateContext<string>, incoming: PriceIdSelect ) {
    this.store.dispatch(new EntityIdSelect({partner_id : incoming.priceId}));
  }

  updateSkuLocation(skuId: string, geo: GeographicArea) {
    const queryParams = {
      tiers_concat: this.activatedRoute.params['tiers_concat'],
      sku_id: this.activatedRoute.params['sku_id'],
      engine: this.activatedRoute.params['engine'],
    };

    let newQueryParams = {};
      newQueryParams['tiers_concat'] = toTierConcat(geo);
      newQueryParams['sku_id'] = skuId;
      newQueryParams['engine'] = Engine.Marketplace.toString();
    if (queryParams !== newQueryParams) {
      const url = this.router.createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: newQueryParams
      }).toString();
      this.location.replaceState(url);
    }
  }
}

function isGeoAreaValid(geoArea: GeographicArea) {
  return !!geoArea?.id;
}
export const STATES = [
  EngineState,
  EntityIdNotFoundState,
  GeoAreaFoundState,
  PricesIdsState,
  QueryContextState,
  SkuState,
  TimelinesToDisplayState,
  TypeState,
  JSONDisplayState,
  ParametersState,
  ExplainDisplayState
];
