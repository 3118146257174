import { ElementRef } from '@angular/core';
import { NrtModel } from '../../model/nrt-model';

export function handleDragAndDrop(dropZone: ElementRef, sideEffect: ((parsed: NrtModel[]) => void)) {
  const allowDrag = (e: any) => {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
  };

  const showDropZone = () => dropZone.nativeElement.style.visibility = 'visible';
  const hideDropZone = () => dropZone.nativeElement.style.visibility = 'hidden';
  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    hideDropZone();
    const dataTransfer = e.dataTransfer;
    if (!dataTransfer) {
      return;
    }
    const reader = new FileReader();
    const files = dataTransfer.files;
    reader.readAsText(files[0], 'UTF-8');
    reader.onload = ev => {
      const message = (ev.target as any).result;
      let parsed = JSON.parse(message);
      if (Array.isArray(parsed)) {
        // The file is probably the output of the step by step API
        sideEffect(parsed);
      } else if (parsed.input !== undefined) {
        sideEffect([parsed]);
      } else {
        alert('Can\'t parse this file');
      }
    };
  };

  window.addEventListener('dragenter', () => showDropZone());
  dropZone.nativeElement.addEventListener('dragenter', allowDrag);
  dropZone.nativeElement.addEventListener('dragover', allowDrag);
  dropZone.nativeElement.addEventListener('dragleave', () => hideDropZone());
  dropZone.nativeElement.addEventListener('drop', (e: DragEvent) => handleDrop(e), false);
}
