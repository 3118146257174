import {Component, OnDestroy, OnInit} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import { GeographicArea } from '../../model/geographic-area';
import { GeoAreaFoundState } from '../../state/action/geo-area';
import { QueryContextState } from '../../state/action/state';
import { GeoAreaSearch, GeoAreaSelect } from '../../state/actions';
import { QueryContext } from '../../state/model/entity-geo-area';
import { FormControl }  from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-geo-picker',
  templateUrl: './geo-picker.component.html',
})
export class GeoPickerComponent implements OnInit, OnDestroy {

  @Select(GeoAreaFoundState) found$: Observable<GeographicArea[]>;
  @Select(QueryContextState) state$: Observable<QueryContext>;

  destroy$: Subject<void>;

  geoAreaForm: FormControl;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.destroy$ = new Subject<void>();
    this.geoAreaForm =  new FormControl<GeographicArea>(undefined);

    this.state$.pipe(takeUntil(this.destroy$))
      .subscribe(newQueryContext => {
        if (!isEqual(newQueryContext.geoArea, this.geoAreaForm.value)) {
          this.geoAreaForm.setValue(newQueryContext.geoArea);
        }
      });
    this.manageGeoAreaChanges();
  }

  private manageGeoAreaChanges() {
    this.geoAreaForm.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged(isEqual))
      .subscribe((newGeoAreaValue: any) => {
        this.store.dispatch(new GeoAreaSelect(newGeoAreaValue))
      });
  }

  search(event: any) {
    this.store.dispatch(new GeoAreaSearch(event.query));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
