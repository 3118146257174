import { Pipe, PipeTransform } from '@angular/core';
import { beautify } from '../../util/string';

@Pipe({ name: 'beautify' })
export class BeautifyPipe implements PipeTransform {

  transform(value: string): string {
    return beautify(value);
  }
}
