import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import { isEntityIdentifier } from 'src/app/model/entity-id';
import { RpcService } from '../../api/rpc.service';
import { EntityIdentifier } from '../../model/entity-identifier';
import { QueryContextState } from '../../state/action/state';
import { QueryContext } from '../../state/model/entity-geo-area';
import { Type } from 'src/app/model/type';
import { Item } from 'src/app/model/item';
import { TypeSelect} from '../../state/actions';
import { EntityIdSelect } from '../../state/actions';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-items-hierarchy',
  templateUrl: './items-hierarchy.component.html',
})
export class ItemsHierarchyComponent implements OnInit, OnDestroy {

  destroy$: Subject<void>;

  @Select(QueryContextState) queryContextState$: Observable<QueryContext>;

  items: Item[];
  hierarchyLoaded: boolean = false;

  constructor(private rpcService: RpcService, private store: Store) {}

  ngOnInit(): void {
    this.destroy$ = new Subject();
      this.queryContextState$
       .pipe(takeUntil(this.destroy$))
       .subscribe(queryContext => {
       if (isEntityIdentifier(queryContext.entityId)) {
         const displayedCode: any = queryContext.entityId.code
         this.rpcService.searchItems(queryContext.entityId.type, displayedCode)
           .pipe(takeUntil(this.destroy$))
           .subscribe(res => {
             this.items = res;
             this.hierarchyLoaded = true;
           });
       }
     });
  }

  refreshEntity(code:number, type:string){
    this.store.dispatch(new TypeSelect(Type[type]))
      .pipe(takeUntil(this.destroy$))
      .subscribe( () => this.store.dispatch(new EntityIdSelect(<EntityIdentifier> { code: code, type: type }))
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete()
  }
}
