import {Injectable} from '@angular/core';
import {env} from './env';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {NotificationsService} from './notifications.service';
import {UserSupportMetric} from '../model/metric/user-support-metric';
import {BehaviorSubject, EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserMetricService {

  private url= env.apiUrl + '/support-ui/track-user-action';

  userIsAuthenticated$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient,
              private notificationsService: NotificationsService) {}

  sendMetrics (action : string) {
    const userSupportMetric : UserSupportMetric = { action };
    return this.http
      .post(this.url, userSupportMetric)
      .pipe(catchError(e => this.handleError(e)))
      .subscribe();
  }

  setUserIsAuthenticated(isAuth : boolean) {
    this.userIsAuthenticated$.next(isAuth);
  }

  handleError(error: HttpErrorResponse)  {
    console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
    this.notificationsService.error('Something wrong happened...', `Backend returned code ${error.status}`);
    return EMPTY;
  }
}
