import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { CsvExportPrice } from '../model/csv-export-prices';
import { CsvUploadPrices } from '../model/csv-upload-prices';
import { env } from './env';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ExportPriceService {

  private url= env.apiUrl + '/v4/prices/exports';

  constructor(private http: HttpClient,
              private notificationsService: NotificationsService) {}
  getExports(showAll: boolean): Observable<CsvExportPrice[]> {
    return this.http.get<CsvExportPrice[]>(this.url+'?showAll='+showAll)
      .pipe(catchError(e => this.handleError(e)));
  }

  getCsvIn(id: string) : Observable<HttpResponse<Blob>>{
    return this.http.get<Blob>(this.url + '/' + id + '/csvIn', { observe: 'response', responseType: 'blob' as 'json'});
  }

  getErrors(id: string) : Observable<HttpResponse<Blob>>{
    return this.http.get<Blob>(this.url + '/' + id + '/errors', { observe: 'response', responseType: 'blob' as 'json'});
  }

  delete(id: string) : Observable<string>{
    return this.http.delete<string>(this.url + '/' + id);
  }

  getCsvOut(id: string) : Observable<HttpResponse<Blob>>{
    return this.http.get<Blob>(this.url + '/' + id + '/csvOut', { observe: 'response', responseType: 'blob' as 'json'});
  }
  sendExportPrices(exportPrices: string, name: string, referenceDate: string): Observable<CsvExportPrice> {
    return this.http.post<CsvExportPrice>(this.url+'?name='+name+'&referenceDate='+referenceDate, exportPrices);
  }


  handleError(error: HttpErrorResponse)  {
    console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
    this.notificationsService.error('Something wrong happened...', `Backend returned code ${error.status}`);
    return EMPTY;
  }

}

