import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { CsvUploadPrices } from '../model/csv-upload-prices';
import { env } from './env';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UploadPriceService {

  private url= env.apiUrl + '/v3/retail/prices/upload';

  constructor(private http: HttpClient,
              private notificationsService: NotificationsService) {}
  simulatePrices(prices: string): Observable<CsvUploadPrices> {
    let dryRun: HttpParams = new HttpParams().set('dry_run', true);
    return this.http.post<CsvUploadPrices>(this.url, prices, {params: dryRun})
      .pipe(catchError(e => this.handleError(e)));
  }

  uploadPrices(prices: string): Observable<CsvUploadPrices> {
    let dryRun: HttpParams = new HttpParams().set('dry_run', false);
    return this.http.post<CsvUploadPrices>(this.url, prices, {params: dryRun})
      .pipe(catchError(e => this.handleError(e)));
  }

  handleError(error: HttpErrorResponse)  {
    console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
    this.notificationsService.error('Something wrong happened...', `Backend returned code ${error.status}`);
    return EMPTY;
  }

}

