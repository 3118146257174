import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { OAuthModule } from 'angular-oauth2-oidc';
import {ConfirmationService, MessageService} from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessageModule } from 'primeng/message';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { environment } from '../environments/environment';
import { ApiKeyInterceptor } from './api/api-key-interceptor';
import { allowedUrls } from './api/env';
import { NotificationsService } from './api/notifications.service';
import { RpcService } from './api/rpc.service';
import { AppRoutingModule } from './app-routing.module';
import { ManageComponent } from './manage';
import { RootComponent } from './root';
import { AppComponent } from './app.component';
import { STATES } from './state/action/state';
import { ActionBarComponent } from './ui/action-bar/action-bar.component';
import { EnginePickerComponent } from './ui/engine-picker/engine-picker.component';
import { EntityPickerMarketplaceComponent } from './ui/entity-picker-marketplace/entity-picker-marketplace.component';
import { EntityPickerRetailComponent } from './ui/entity-picker-retail/entity-picker-retail.component';
import { EntityPickerSecondLifeComponent } from './ui/entity-picker-second-life/entity-picker-second-life.component';
import { EntityPickerSkuComponent } from './ui/entity-picker-sku/entity-picker-sku.component';
import { EntityPickerTypeComponent } from './ui/entity-picker-type/entity-picker-type.component';
import { ZoomComponent } from './ui/gantt/addon/zoom/zoom.component';
import { GanttComponent } from './ui/gantt/gantt.component';
import { GeoHierarchyDisplayComponent } from './ui/geo-hierarchy-display/geo-hierarchy-display.component';
import { GeoPickerComponent } from './ui/geo-picker/geo-picker.component';
import { ItemsHierarchyComponent } from './ui/items-hierarchy/items-hierarchy.component';
import { NavbarComponent } from './ui/navbar/navbar.component';
import { ParameterComponent } from './ui/parameter/parameter.component';
import { UserCanDirective } from './ui/permission/user-can.directive';
import { UserCannotDirective} from './ui/permission/user-cannot.directive';
import { BasicHtmlPipe } from './ui/pipe/basic-html.pipe';
import { BeautifyPipe } from './ui/pipe/beautify.pipe';
import { CurrentModelPipe } from './ui/pipe/current-model.pipe';
import { PrettyJsonPipe } from './ui/pipe/prettyjson.pipe';
import { HasRoleDirective } from './ui/permission/has-role.directive';
import { SettingsMenuComponent } from './ui/settings-menu/settings-menu.component';
import { UploadComponent } from './upload';
import { DownloadComponent } from './download';
import { MessagesModule } from 'primeng/messages';
import { EntityPickerPriceIdComponent } from './ui/entity-picker-price-id/entity-picker-price-id.component';
import { StepsModule } from 'primeng/steps';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RippleModule } from 'primeng/ripple';
import { ScrollToTopComponent } from './ui/scroll-to-top/scroll-to-top.component';
import { FooterComponent } from './ui/footer/footer.component';

@NgModule({
  declarations: [
    ActionBarComponent,
    AppComponent,
    BasicHtmlPipe,
    BeautifyPipe,
    CurrentModelPipe,
    EnginePickerComponent,
    EntityPickerMarketplaceComponent,
    EntityPickerRetailComponent,
    EntityPickerSecondLifeComponent,
    EntityPickerTypeComponent,
    EntityPickerSkuComponent,
    EntityPickerPriceIdComponent,
    GanttComponent,
    GeoHierarchyDisplayComponent,
    GeoPickerComponent,
    UserCanDirective,
    ItemsHierarchyComponent,
    NavbarComponent,
    ParameterComponent,
    PrettyJsonPipe,
    ZoomComponent,
    HasRoleDirective,
    UploadComponent,
    DownloadComponent,
    ManageComponent,
    RootComponent,
    UserCannotDirective,
    SettingsMenuComponent,
    ScrollToTopComponent,
    FooterComponent

  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ApiKeyInterceptor,
    multi: true
  }, MessageService, NotificationsService, RpcService, ConfirmationService],
  imports: [
    AppRoutingModule,
    AutoCompleteModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CardModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    FormsModule,
    ProgressSpinnerModule,
    HttpClientModule,
    InputSwitchModule,
    StepsModule,
    FileUploadModule,
    ConfirmDialogModule,
    InputTextModule,
    KeyFilterModule,
    MessageModule,
    NgxsModule.forRoot(STATES, { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NoopAnimationsModule,
    OAuthModule.forRoot({ resourceServer: { allowedUrls, sendAccessToken: true } }),
    PaginatorModule,
    PanelModule,
    ToastModule,
    TooltipModule,
    TagModule,
    TreeModule,
    MessagesModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    RippleModule,
    SelectButtonModule,
    DividerModule,
  ],
  bootstrap: [RootComponent]
})
export class AppModule {
}
