import { Dictionary } from '../util/object';

const envs: Dictionary<EnvVariable> = {
  localhost: {
    fedIdUrl: 'https://preprod.idpdecathlon.oxylane.com',
    clientId: 'C9933289406ffdbd42512cd70ce09dc75be3be30c',
    clientSecret: 'ShkRrLr2Ernq7Ad6Xo8fPT7OIi9n4OYSRst1Jeq3e5Bhogdeb6X3EHhkzjUKeUw9',
    apiUrl: 'http://localhost:8080'
  },
  'nrt-gcp': {
    fedIdUrl: 'https://preprod.idpdecathlon.oxylane.com',
    clientId: 'C9933289406ffdbd42512cd70ce09dc75be3be30c',
    clientSecret: 'ShkRrLr2Ernq7Ad6Xo8fPT7OIi9n4OYSRst1Jeq3e5Bhogdeb6X3EHhkzjUKeUw9',
    apiUrl: 'http://masterprice-api-op:8080'
  },
  'support-ui.masterprice-qualification.decathlon.net': {
    fedIdUrl: 'https://preprod.idpdecathlon.oxylane.com',
    clientId: 'C9933289406ffdbd42512cd70ce09dc75be3be30c',
    apiUrl: 'https://api-global.preprod.decathlon.net/prices/applicable-price',
    clientSecret: 'ShkRrLr2Ernq7Ad6Xo8fPT7OIi9n4OYSRst1Jeq3e5Bhogdeb6X3EHhkzjUKeUw9',
    apiKey: 'f89ba91d-540a-49da-8a04-f44b7129a5da',
  },
  'support-ui.masterprice.decathlon.net': {
    fedIdUrl: 'https://idpdecathlon.oxylane.com',
    clientId: 'C2c135f87bd85dad8619878577bf22d390d25c6fe',
    apiUrl: 'https://api.decathlon.net/prices/applicable-price',
    clientSecret: 'SN5pxU109sVG4cqh7jQE7tKaGLKJAZjOKdSX6ALkeIkvNonTYYsKuHrmEOUP4Wop',
    apiKey: '2aa5c251-100a-48f0-8295-615b1521c424'
  },
  'masterprice-support-cnpr.dktapp.cloud': {
    fedIdUrl: 'https://idpdecathlon.decathlon.com.cn',
    clientId: 'Ccf89320cf816006e3f35270100f4f9d2587534fa',
    clientSecret: 'SahguYx6sjxI48mQ5Wl0rmTY12iX75JWZm02YtFZED6MdMB6iUSZTxQhZQzMWqPX',
    apiUrl: 'https://masterprice-cnpr.dktapp.cloud'
  },
  'masterprice-supportui-cn.pp.dktapp.cloud': {
    fedIdUrl: 'https://idpdecathlon-pp.decathlon.com.cn',
    clientId: 'Cbc3f04805df27b729d12365444513dd2a5f3eb60',
    clientSecret: 'StJzFZf7iZa4FRoWAyBATsqH24pDZVhiiUlQhPwzFpfvrHNsa5Q2nsnjwFDl09We',
    apiUrl: 'https://masterprice-cn.pp.dktapp.cloud'
  }
};

interface EnvVariable {
  fedIdUrl: string;
  clientId: string;
  clientSecret: string;
  apiUrl: string;
  apiKey?: string;
}

export function overrideEnvironment(hostname: string) {
  env.fedIdUrl = envs[hostname].fedIdUrl;
  env.clientId = envs[hostname].clientId;
  env.apiUrl = envs[hostname].apiUrl;
}

// window might be null for E2E tests for example
function guessEnv() {
  try {
    return envs[window.location.hostname];
  } catch (e) {
    return envs.localhost;
  }
}

export const env = guessEnv();
export const allowedUrls = Object.values(envs).map(value => value.apiUrl);
