<nav class='navbar navbar-expand-lg navbar-light bg-light'>
  <span>
    <a class='navbar-brand' href='/'>Masterprice</a>
    Worldwide selling price calculation
  </span>
</nav>
<p-confirmDialog></p-confirmDialog>
<p-toast position='top-center'></p-toast>
<div *appUserCan="['RETAIL_READ_OWN_REQUESTED_PRICES', 'RETAIL_READ_ALL_REQUESTED_PRICES_SO', 'RETAIL_READ_ALL_REQUESTED_PRICES_STORE', 'RETAIL_READ_ALL_REQUESTED_PRICES_ECOM']; operation 'OR';
notificationMessage 'You don\'t have the right to export prices'" class='manage'>
  <div class='upload-file'>
    <ng-container>
      <div *ngIf='isLoading' class='download-wait'>
        <h1>Loading...</h1>
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div *ngIf='isDeleting' class='download-wait'>
        <h1>Deleting price...</h1>
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div>
        <h4 class='upload-title'>Manage prices</h4>
        <h5 class='upload-subtitle'>This page will allow you to manage requested prices</h5>
      </div>

      <!-------------------------------------------------------- VERIFY STEP -------------------------------------------------------->
      <div>
        <div>
          <br/>
        </div>
        <ng-container>
          <p-table (onLazyLoad)='refreshFromTab($event)' [filters]='filters'
                   [globalFilterFields]="['entity']"
                   [lazy]='true'
                   [paginator]='true'
                   [rowsPerPageOptions]='[5,20, 50, 100]'
                   [rows]='20'
                   [showCurrentPageReport]='true'
                   [showFirstLastIcon]='false'
                   [tableStyle]="{ 'min-height': '300px' }"
                   [totalRecords]='totalPrices'
                   [value]='prices'
                   currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'>
            <ng-template pTemplate='header'>
              <tr>
                <th class='id'>id</th>
                <th class='user'>User</th>
                <th class='entity'>
                  Entity
                </th>
                <th class='tiers'>
                  Geo Area

                </th>
                <th class='value'>Value</th>
                <th class='startDate'>Start date</th>
                <th class='endDate'>End date</th>
                <th>Actions</th>
              </tr>
              <tr>
                <th>
                  <p-columnFilter [ngModel]='priceId' [showMenu]=false class='filter' field='priceId' type='text'>
                    <small></small></p-columnFilter>
                </th>
                <th>
                  <p-columnFilter [ngModel]='userId' [showMenu]=false class='filter' field='user' type='text'>
                    <small>{{connectedUserId}}</small></p-columnFilter>
                </th>

                <th>
                  <p-columnFilter [showMenu]='false' field='entityTypes' matchMode='in'>
                    <ng-template let-filter='filterCallback' let-value pTemplate='filter'>
                      <p-dropdown (onChange)='filter($event.value)' [ngModel]='entityType'
                                  [options]='availableEntityTypes' [showClear]='false'>
                        <ng-template let-option pTemplate='item'>
                          <p-tag [severity]='getEntitySeverity(option)' [value]='option'></p-tag>
                        </ng-template>
                      </p-dropdown>
                    </ng-template>
                  </p-columnFilter>
                  <p-columnFilter [ngModel]='entityCode' [showMenu]=false class='filter' field='entityCode' type='text'>
                    <small></small></p-columnFilter>
                </th>
                <th>
                  <p-columnFilter [showMenu]='false' field='geoAreaTypes' matchMode='in'>
                    <ng-template let-filter='filterCallback' let-value pTemplate='filter'>
                      <p-dropdown (onChange)='filter($event.value)' [ngModel]='geoAreaType'
                                  [options]='availableGeoAreaTypes' [showClear]=false>
                        <ng-template let-option pTemplate='item'>
                          <p-tag [severity]='getGeoSeverity(option)' [value]='option'></p-tag>
                        </ng-template>
                      </p-dropdown>
                    </ng-template>
                  </p-columnFilter>
                  <p-columnFilter [ngModel]='tierCode' [showMenu]=false class='filter' field='geoCode' type='text'>
                    <small></small></p-columnFilter>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template let-lineItem pTemplate='body'>
              <tr>
                <td class='id center'>{{lineItem.id}}</td>
                <td class=' center'>{{humanReadableDate(lineItem.emission_date)}}<br/> by {{lineItem.user_id}}</td>
                <td class='center'>
                  <p-tag *ngIf='lineItem.entity_identifier.type=="ITEM"' severity='success'>
                    ITEM {{lineItem.entity_identifier.code}}</p-tag>
                  <p-tag *ngIf='lineItem.entity_identifier.type=="MODEL"' severity='info'>
                    MODEL {{lineItem.entity_identifier.code}}</p-tag>
                  <br/>
                </td>
                <td class='center'>
                  <p-tag *ngIf='lineItem.tiers.type ==7' severity='success'>Store  {{lineItem.tiers.code}}</p-tag>
                  <p-tag *ngIf='lineItem.tiers.type ==15' severity='danger'>Sales Org  {{lineItem.tiers.code}}</p-tag>
                  <p-tag *ngIf='lineItem.tiers.type ==50' severity='info'>Ecommerce  {{lineItem.tiers.code}}</p-tag>
                </td>
                <td class='center'>{{ lineItem.value | currency: lineItem.currency  }}</td>
                <td class='center'>{{humanReadableDate(lineItem.start_date)}}<br/>
                  <small *ngIf='isBeforeNow(lineItem.start_date)' class='date-info started-info'>started</small></td>
                <td class='center'>{{humanReadableDate(lineItem.end_date)}}<br/>
                  <small *ngIf='isBeforeNow(lineItem.end_date)' class='date-info ended-info'>ended</small></td>
                <td>
                  <p-button (click)='delete(lineItem)'
                            *ngIf='!isBeforeNow(lineItem.start_date) && canDelete(lineItem)' icon='pi pi-delete' label='Delete'
                            styleClass='p-button-danger center'></p-button>
                  <p-button (click)='delete(lineItem)'
                            *ngIf='isBeforeNow(lineItem.start_date)  && canDelete(lineItem)' [disabled]='isBeforeNow(lineItem.end_date)'
                            icon='pi pi-delete' label='Close' styleClass='p-button-warning center'></p-button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>

      <div class='home'>
        <p-button icon='pi pi-home' label='Home' routerLink='' styleClass='back p-button-secondary'></p-button>
      </div>
    </ng-container>
  </div>

</div>
<div *appUserCannot="['EXPORT_PRICES']; operation 'OR';" class='upload'>
  <p-messages [severity]="'error'">
    <ng-template>
      <div>
        <h1>Not authorize</h1>
        <br>You don't have the permissions to export prices
        <br>
        <button class='p-button-raised mr-2' pButton pRipple routerLink='' type='button'>Back home</button>
      </div>
    </ng-template>
  </p-messages>
</div>
