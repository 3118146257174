<div>
  <p-contextMenu #contextMenu [model]='menuItems$ | async' [target]='element' appendTo="body"></p-contextMenu>
  <div >
    <p-dialog [(visible)]='showParameter' header='Parameters'>
      <app-parameter></app-parameter>
    </p-dialog>
    <p-dialog [(visible)]='showGeoArea' header='Hierarchy of geographic areas'>
      <app-geo-hierarchy-display ></app-geo-hierarchy-display>
    </p-dialog>
    <p-dialog [(visible)]='showItemsHierarchy' header='Items hierarchy'>
      <app-items-hierarchy></app-items-hierarchy>
    </p-dialog>
  </div>
</div>


