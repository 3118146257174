import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { env } from './env';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(env.fedIdUrl)) {
      return next.handle(req);
    }

    return env.apiKey !== undefined
      ? next.handle(req.clone({ headers: req.headers.set('x-api-key', env.apiKey) }))
      : next.handle(req);
  }
}
