import { Component } from '@angular/core';
import { NrtModel } from '../../../../model/nrt-model';
import { filterUndef } from '../../../../util/object';
import { daysBetweenDate, extractAllDates } from '../../model/date';
import { gantt } from 'dhtmlx-gantt';

@Component({
  selector: 'app-gantt-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent {
  zoomConfig = zoomConfiguration;

  zoomIn() {
    gantt.ext.zoom.zoomIn();
  }

  zoomOut() {
    gantt.ext.zoom.zoomOut();
  }

  zoom(value: string) {
    ganttZoom(value);
  }

  collapse(open: boolean) {
    gantt.eachTask(task => task.$open = open);
    gantt.render();
  }
}

function ganttZoom(value: string) {
  gantt.ext.zoom.setLevel(value);
}

export function initZoom() {
  gantt.ext.zoom.init(zoomConfiguration);
  gantt.ext.zoom.attachEvent('onAfterZoom', (level, config) => {
    const radioButton = document.querySelector('.gantt_radio[value=\'' + config.name + '\']');
    if (!!radioButton) {
      // @ts-ignore
      radioButton.checked = true;
    }
  });
}

const zoomConfiguration = {
  useKey: 'ctrlKey',
  trigger: 'wheel',
  // @ts-ignore
  element: () => gantt.$root.querySelector('.gantt_task'),
  levels: [
    {
      name: 'hour',
      scale_height: 27,
      min_column_width: 80,
      scales: [
        { unit: 'day', step: 1, format: '%d %M' },
        { unit: 'hour', step: 1, format: '%hh' }
      ]
    },
    {
      name: 'day',
      scale_height: 27,
      min_column_width: 80,
      scales: [
        { unit: 'day', step: 1, format: '%d %M' }
      ]
    },
    {
      name: 'week',
      scale_height: 50,
      min_column_width: 50,
      scales: [
        {
          unit: 'week', step: 1, format: date => {
            const dateToStr = gantt.date.date_to_str('%d %M');
            const endDate = gantt.date.add(date, -6, 'day');
            const weekNum = gantt.date.date_to_str('%W')(date);
            return '#' + weekNum + ', ' + dateToStr(date) + ' - ' + dateToStr(endDate);
          }
        },
        { unit: 'day', step: 1, format: '%j %D' }
      ]
    },
    {
      name: 'month',
      scale_height: 50,
      min_column_width: 120,
      scales: [
        { unit: 'month', format: '%F, %Y' },
        { unit: 'week', format: 'Week #%W' }
      ]
    },
    {
      name: 'quarter',
      height: 50,
      min_column_width: 90,
      scales: [
        { unit: 'month', step: 1, format: '%M' },
        {
          unit: 'quarter', step: 1, format: date => {
            const dateToStr = gantt.date.date_to_str('%M');
            const endDate = gantt.date.add(gantt.date.add(date, 3, 'month'), -1, 'day');
            return dateToStr(date) + ' - ' + dateToStr(endDate);
          }
        }
      ]
    },
    {
      name: 'year',
      scale_height: 50,
      min_column_width: 30,
      scales: [
        { unit: 'year', step: 1, format: '%Y' }
      ]
    }
  ]
};

export function autoZoom(nrtModel: NrtModel) {
  const dates = filterUndef(extractAllDates(nrtModel));
  if (dates.length < 2) {
    ganttZoom('month');
  } else {
    const numberOfDays = daysBetweenDate(nrtModel);
    if (numberOfDays < 10) {
      ganttZoom('week');
    } else if (numberOfDays < 30) {
      ganttZoom('month');
    } else if (numberOfDays < 180) {
      ganttZoom('quarter');
    } else {
      ganttZoom('year');
    }
  }
}
