import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Select } from '@ngxs/store';
import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.js';
import { Observable, Subject } from 'rxjs';
import { PriceTask } from '../../model/gantt/price-task';
import { JSONDisplayState } from '../../state/action/state';
import { currentModel, TimelinesToDisplay } from '../../state/model/timelines-to-display';
import { autoZoom, initZoom } from './addon/zoom/zoom.component';
import { dateParser, safeParse } from './model/date';
import { convert } from './model/price.to.task.converter';
import { JsonDisplay } from '../../state/model/json-display';
import { Price } from 'src/app/model/price';
import { gantt } from 'dhtmlx-gantt';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-gantt',
  styleUrls: ['./gantt.component.scss'],
  templateUrl: './gantt.component.html',
})
export class GanttComponent implements OnInit, OnChanges, OnDestroy {
  @Select(JSONDisplayState) jsonDisplay$: Observable<JsonDisplay>;

  @ViewChild('gantt', { static: true })
  ganttContainer!: ElementRef;
  onInitCalled = false;

  @Input()
  timelinesToDisplay!: TimelinesToDisplay;

  destroy$: Subject<void>;
  constructor() {}

  ngOnInit() {
    gantt.plugins({
        marker: true,
        tooltip: true 
    });

    this.destroy$ = new Subject();

    gantt.attachEvent('onTemplatesReady', () => {
      gantt.templates.xml_format = date => date.toISOString();
      gantt.templates.task_class = (start, end, task: PriceTask) => !!task.price ? 'price' : 'group';
      gantt.templates.grid_folder = () => icon('pi-folder');
      gantt.templates.grid_file = (task: PriceTask) => {
        return icon(getIcon(task.price));
      };

    gantt.templates.grid_row_class = function(start, end, task){
      if (task?.price?.scope?.entity_type === 'MODEL') {
        return "scope-color";
      }
    };

    }, '');
    gantt.templates.tooltip_text = (start, end, task: PriceTask) => task.tooltip;
    gantt.config.tooltip_offset_x = 10;
    gantt.config.tooltip_offset_y = 0;
    gantt.config.columns = [
      { name: 'text', label: ' ', tree: true, width: 220 },
      { name: 'user_id', label: 'User', align: 'center', width: 40 },
      { name: 'start_date_text', label: 'Start date', align: 'center', width: 80 },
      { name: 'end_date_text', label: 'End date', align: 'center', width: 80 },
      { name: 'icon', label: ' ', align: 'center', width: 40 }
    ];
    gantt.config.readonly = true;
    gantt.config.autosize = 'y';

    gantt.config.keyboard_navigation = true;
    // @ts-ignore
    gantt.config.drag_timeline = { useKey: false };
    gantt.init(this.ganttContainer.nativeElement);
    initZoom();
    this.onInitCalled = true;
    this.refreshModel();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshModel();
  }

  refreshModel() {
    if (this.onInitCalled) {
      gantt.clearAll();
      const nrtModel = currentModel(this.timelinesToDisplay);

      if (this.modelIsValid()) {
        // @ts-ignore
        gantt.templates.xml_date = dateParser(nrtModel);
        const data = convert(nrtModel);
        nrtModel.input.product_process_units.forEach(ppu => {
          if (ppu.current_date_time != null) {
            gantt.addMarker({
              start_date: safeParse(ppu.current_date_time),
              text: "Current date"
            });
          }
        });
        gantt.parse({ data, links: [] });
        gantt.render();
        autoZoom(nrtModel);
      }
    }
  }

  modelIsValid() {
    return currentModel(this.timelinesToDisplay)?.input?.product_process_units?.length ?? 0;
  }
}
function icon(name: string): string {
  return `<div class='gantt_tree_icon gant pi ${name}'></div>`;
}
function getIcon(price: Price): string {
  // TODO : replace support injection with a real system unique user id and role
  const icons = {
    INJECTION: 'pi-download',
    STORE: "pi-shopping-cart",
    DYNPRICE :'pi-sort-alt',
    POINT_OF_SALE: "pi-shopping-cart",
    SALES_ORGANIZATION: "pi-flag",
    ECOMMERCE: "pi-globe",
    WORLD: "pi-globe",
    ABSOLUT: "pi-lock"
  };

  switch (true) {
    case price?.is_absolute:
      return icons['ABSOLUT'];
    case price?.user_id === 'INJECTION':
      return icons.INJECTION;
    case price?.user_id === 'NETRIVALSDYNPRICE' || price?.user_id === 'DYNAMICPRICINGUK':
        return icons.DYNPRICE;
    case price?.price_origin_type in icons:
      return icons[price.price_origin_type!];
    case price?.scope?.geographic_scope in icons:
      return icons[price.scope!.geographic_scope!];
    default:
      return null;
  }
}
