<nav class='navbar navbar-expand-lg navbar-light bg-light'>
  <span>
    <a class='navbar-brand' href='/'>Masterprice</a>
    Worldwide selling price calculation
  </span>
</nav>
<p-confirmDialog></p-confirmDialog>
<p-toast position='top-center'></p-toast>
<div class='upload' *appUserCan="['SAVE_MULTIPLE_PRICE_USING_SUPPORT_UI']; operation 'OR';
notificationMessage 'You don\'t have the right to add prices'">

    <p-steps [model]="uploadPriceSteps"
             [readonly]="true"
             [activeIndex]="activeIndex"></p-steps>

    <div class="upload-file">
      <ng-container *ngIf="!isLoading; else loading">
        <!-------------------------------------------------------- UPLOAD STEP -------------------------------------------------------->
        <div>
          <p-card *ngIf="!  isPriceSentStep()"
            subheader="Your price decision can have an impact"
          class="warning-card">
            <ng-template pTemplate="header">
             <div class='warning-card-header'> Be careful.</div>
            </ng-template>
         <p class='warning-content' color='warn'>
           <b>Legal</b> if you decide a higher price than the one decided by your country or the brand (Ex spot TV or radio)<br/>
            <b> Business</b> if your price impacts the expected reference price of further commercial operations in your Country <br/><i>(Ex a crossed out price lower than expected  for the next country commercial operation)</i>

         </p>
            <ng-template pTemplate="footer">

            </ng-template>
          </p-card>

        </div>
        <div *ngIf="isUploadFileStep()" >
          <h4 class="upload-title">Upload prices</h4>
          <h5 class="upload-subtitle">Upload your file containing the prices</h5>
          <div class="notification">
              <span>The maximum prices per file is set to 100</span><br/>
              <span>Beyond this number, and for a maximum of 5000, no simulation will be done.</span><br/>
              <span>You can find the documentation <a href="https://docs.google.com/presentation/d/12TaWwJUySjYJU02x4cjzbz7ve2OctQyDWozd_5IrZwo/edit#slide=id.g1929efc97b8_0_0" target="_blank">here</a></span>
          </div>

          <div class="actions">
              <p-fileUpload styleClass="upload" mode="basic" chooseLabel="Pick your file"
                            [customUpload]="true"
                            accept=".csv"
                            (uploadHandler)="readFile($event)">

              </p-fileUpload>
            <p-button styleClass="p-button-help" label="File example" icon="pi pi-download" (click)="downloadFile()"></p-button>
          </div>
        </div>

        <!-------------------------------------------------------- VERIFY STEP -------------------------------------------------------->
        <div *ngIf="isVerifyPriceStep()">
          <h4 class="upload-title">Verified prices</h4>
          <h5 class="upload-subtitle">Here are your verified prices</h5>
          <div class="notification">
              <span class="error" *ngIf="hasErrors">Prices validation failed, please fix the errors in your file before uploading it again
          </span>
            <span class="warning" *ngIf="hasWarnings">
              The prices uploaded will be different than the prices you sent. Please check the modification
            </span>
          </div>

          <ng-container *ngIf="isFileContentValid(); else displayErrors">
            <p-table [value]="verifiedPrices.csv_upload_line_reports">
              <ng-template pTemplate="header">
                <tr>
                  <th class="line-number">Line number</th>
                  <th class="item-code">Entity type</th>
                  <th class="item-code">Entity code</th>
                  <th class="tiers-type">Tier type</th>
                  <th class="tiers-code">Tier code</th>
                  <th class="date">Start date</th>
                  <th class="date">End date</th>
                  <th class="nature">Nature</th>
                  <th class="value">Value</th>
                  <th>Currency</th>
                  <th>Comments</th>
                  <th>Status</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-lineItem>
                <tr>
                  <td class="center">{{ lineItem.line_index }}</td>
                  <ng-container *ngFor="let column of lineItem.columns; let indexColumn = index">
                    <ng-container *ngIf="indexColumn < NUMBER_COLUMNS">
                      <td [ngClass]="getColumnClass(column, lineItem)">
                        <ng-container *ngIf="hasValueChanged(column)">
                          <div [ngClass]="{crossed: isLineNotBeingValidated(lineItem)}">
                            {{ column.raw_value }}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="column.applied_value">
                          {{ column.applied_value }}
                        </ng-container>
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="missingColumn(lineItem)">
                    <td *ngFor="let missingColumn of getMissingColumns(lineItem)"></td>
                  </ng-container>

                  <td class="comments">
                    <div class="error" *ngIf="hasError(lineItem)">
                      <ul>
                        <li *ngFor="let error of computeError(lineItem)">{{ error }}</li>
                      </ul>
                    </div>
                    <div class="warning" *ngIf="hasWarning(lineItem)">
                      <ul>
                        <li *ngFor="let warning of computeWarning(lineItem)">{{ warning }}</li>
                      </ul>
                    </div>
                  </td>
                  <td [ngClass]="getStatusClass(lineItem)">
                    {{ lineItem.status }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
          <ng-template #displayErrors>
            <div class="error">
              <ul>
                <li *ngFor="let error of verifiedPrices.errors">{{ error }}</li>
              </ul>
            </div>
          </ng-template>

          <div class="actions">
            <button [ngClass]="getUploadButtonClass()"
                    *ngIf="!hasErrors" pButton
                    pRipple type='button' (click)="uploadPrices()">Upload prices
            </button>

            <button class="p-button-raised p-button-info" pButton
                    pRipple type='button' (click)="goBack()">Back to first step
            </button>
          </div>
        </div>

        <!-------------------------------------------------------- SENT PRICES STEP ------------------------------------------------------->
        <div *ngIf="isPriceSentStep()">
          <h4 class="upload-title">Uploaded prices</h4>
          <h5 class="upload-subtitle">See below the uploaded prices</h5>
          <div class="notification">
            <span>Your prices have been successfully uploaded.
              The timelines calculations make take a few minutes before being available in the support UI
            </span>
          </div>

          <p-table [value]="sentPrices.csv_upload_line_reports">
            <ng-template pTemplate="header">
              <tr>
                <th class="line-number">Line number</th>
                <th class="item-code">Entity type</th>
                <th class="tiers-type">Entity code</th>
                <th class="tiers-code">Tier type</th>
                <th class="tiers-code">Tier code</th>
                <th class="date">Start date</th>
                <th class="date">End date</th>
                <th class="nature">Nature</th>
                <th class="value">Value</th>
                <th>Currency</th>
                <th>Timeline</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-priceSent>
              <tr>
                <td>{{ priceSent.line_index }}</td>
                <ng-container *ngFor="let column of priceSent.columns">
                  <td>
                    <ng-container *ngIf="column.raw_value">
                      {{ column.raw_value }}
                    </ng-container>
                  </td>
                </ng-container>
                <td>
                  <a *ngIf="canBuildTimelineURL(priceSent)" [href]="buildTimeLineURL(priceSent)" target="_blank">
                    <i class="pi pi-link"></i>
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div class="actions">
            <button class="p-button-raised p-button-info" pButton
                    pRipple type='button' (click)="goBack()">Go back
            </button>
          </div>
        </div>

        <div class="home">
          <p-button styleClass="back p-button-secondary" label="Home" icon="pi pi-home" routerLink=""></p-button>
        </div>

        <!----- History ------------->

        <div>
          <div>
            <br/>
            <p-selectButton  *appUserCan="['READ_ALL_UPLOAD_PRICES']" class="toggle" (onChange) = 'toggleChange()' [options]="['Show all','Show mine']" [(ngModel)]="showAll" ></p-selectButton>
            <br/>
          </div>
          <ng-container>
            <p-table [value]="uploadHistory">
              <ng-template pTemplate="header">
                <tr>
                  <th class="userId">User</th>
                  <th class="name">Name</th>
                  <th class="creationDate">Creation date</th>
                  <th class="csv">Csv</th>
                  <th class="errors">Errors</th>
                  <th>Delete</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-lineItem>
                <tr>
                  <td class='user center'>{{lineItem.user_id}}</td>
                  <td class='name center'>{{lineItem.name}}</td>
                  <td class="center">{{ humanReadableDate(lineItem.creation_date) }}</td>
                  <td class="center">
                    <p-button styleClass="p-button-info" label="Input file" icon="pi pi-download" (click)="getCsv(lineItem)"/>
                  </td>
                  <td class='error-button'>
                    <p-button *ngIf="lineItem.number_of_errors!==0"  styleClass="p-button-info" label="Errors file" icon="pi pi-download" (click)="getErrors(lineItem)"/>
                    <p>{{lineItem.number_of_errors}} errors</p>
                  </td>
                  <td><p-button styleClass="p-button-danger" label="Delete" icon="pi pi-delete" (click)="delete(lineItem)"></p-button> </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </div>

        <div class="home">
          <p-button styleClass="back p-button-secondary" label="Home" icon="pi pi-home" routerLink=""></p-button>
        </div>
      </ng-container>
      <ng-template #loading>
        <div class="actions">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </ng-template>

    </div>

</div>
<div class='upload' *appUserCannot="['SAVE_MULTIPLE_PRICE_USING_SUPPORT_UI']; operation 'OR';">
  <p-messages [severity]= "'error'">
    <ng-template>
      <div>
        <h1>Not authorize</h1>
        <br>You don't have the permissions to add prices
        <br>
        <button class="p-button-raised mr-2" pButton pRipple type='button' routerLink="">Back home</button>
      </div>
    </ng-template>
  </p-messages>
</div>
