import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExportPriceService } from '../api/export-price.service';
import { ManagePriceService } from '../api/manage-price.service';
import { RpcService } from '../api/rpc.service';
import { CsvExportPrice, ExportState } from '../model/csv-export-prices';
import { NotificationsService } from '../api/notifications.service';
import { CSV_STATUS, CsvLineReport, CsvPriceColumn, CsvUploadPrices } from '../model/csv-upload-prices';
import { Price } from '../model/price';
import { RequestedPrice } from '../model/RequestedPrice';
import { User } from '../model/user';
import {UserMetricService} from '../api/user-metric.service';
import { UserSupportAction } from '../model/metric/user-support-action';

@Component({
  templateUrl: 'manage.component.html',
  styleUrls: ['./manage.component.scss']})
export class ManageComponent implements OnInit {
  private _isLoading: boolean;
  private _isDeleting: boolean;
  private _prices: Price[];
  public availableGeoAreaTypes = ['STORE','SALES_ORGANIZATION','ECOMMERCE'];
  public availableEntityTypes = ['ITEM','MODEL'];
  public userId;
  public connectedUserId;
  public entityType = this.availableEntityTypes[0];
  public permissions : string[];
  public entityCode;
  public tierCode;
  public priceId;
  public geoAreaType =this.availableGeoAreaTypes[0];
    public totalPrices: number;
  public pageNumber: number = 1;
  public pageSize: number = 20;
  public filters = {'user':{value:''}};

  public exportFile;
  constructor(private managePriceService: ManagePriceService,
              private notificationService: NotificationsService,
              private confirmationService: ConfirmationService,
              private notificationsService: NotificationsService,
              private rpcService: RpcService,
              private userMetricService : UserMetricService) {}

  ngOnInit(): void {
    this.userMetricService.sendMetrics(UserSupportAction.MANAGE);
    this._isLoading = false;
    this.refresh = this.refresh.bind(this);
    this.rpcService.getCurrentUser()
    .subscribe((user) => {
       this.connectedUserId = user.login;
       this.permissions = user.permissions;
       this.filters.user.value = user.login;
       this.userId = user.login;
       this.refresh();
     });
  }

  getGeoSeverity(geo){
    if(geo == 'SALES_ORGANIZATION')
      return 'danger';
    if(geo == 'STORE')
      return 'success';
    if(geo == 'ECOMMERCE')
      return 'info';
    return '';
  }

  getEntitySeverity(entity): string{
    if(entity == 'ITEM')
      return 'success';
    if(entity == 'ECOMMERCE')
      return 'info';
    return '';
  }

  refreshFromTab(event){
    this.pageNumber = event.first/event.rows +1;
    this.pageSize = event.rows;

    if(event.filters?.entityTypes?.value && event.filters.entityTypes.value.length>0)
      this.entityType = event.filters.entityTypes.value;
    else this.entityType = this.availableEntityTypes[0];

    if(event.filters?.geoAreaTypes?.value && event.filters.geoAreaTypes.value.length>0)
      this.geoAreaType = event.filters.geoAreaTypes.value;
    else this.geoAreaType = this.availableGeoAreaTypes[0];

    this.userId = event.filters.user.value;
    this.tierCode = event.filters?.geoCode?.value;
    this.entityCode = event.filters?.entityCode?.value;
    this.priceId = event.filters?.priceId?.value;

    this.refresh();
  }

  canDelete(price: RequestedPrice){
    if(price.user_id == this.userId && this.permissions.indexOf('RETAIL_DELETE_OWN_REQUESTED_PRICES') != -1){
      return true;
    }
    if(price.tiers.type ==15 && this.permissions.indexOf('RETAIL_DELETE_ALL_REQUESTED_PRICES_SO') != -1){
      return true;
    }
    if(price.tiers.type ==7 && this.permissions.indexOf('RETAIL_DELETE_ALL_REQUESTED_PRICES_STORE') != -1){
      return true;
    }
    if(price.tiers.type ==50 && this.permissions.indexOf('RETAIL_DELETE_ALL_REQUESTED_PRICES_ECOM') != -1){
      return true;
    }

    return false;
  }

  refreshCounts(){
    this.managePriceService.getCounts( this.geoAreaType, this.entityType, this.priceId, this.entityCode, this.tierCode, this.userId)
    .subscribe(count => {
      console.log(count);
      this.totalPrices = count;
    }, e => {
    });
  }

  refresh(){
    this.refreshCounts();
    this._isLoading = true;
    this.managePriceService.getPrices(this.pageSize, this.pageNumber, this.geoAreaType, this.entityType, this.priceId, this.entityCode, this.tierCode, this.userId)
    .subscribe(prices => {
      this._isLoading = false;
      this._prices = prices;
    }, e => {
      this.handleError(e);
      this._isLoading = false;
    });
  }

  isBeforeNow(date : string){
    return new Date(date + 'Z') < new Date();
  }

  humanReadableDate(date: string) : string{
    if(date==null)
      return '/';
    return new Date(date).toLocaleString();
  }

  delete(lineItem: Price){
    var message, header;
    if(this.isBeforeNow(lineItem.start_date)){
      message = ' Are you sure that you want to close this price? (end_date will be set to now)';
      header = 'Close price';
    } else {
      message = ' Are you sure that you want to delete this price?';
      header = 'Delete price';
    }

    if(this.isBeforeNow(lineItem.end_date)){
      return false;
    }
    this.confirmationService.confirm({
      message: message,
      header: header,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._isDeleting = true;
        this.managePriceService.delete(lineItem.id).subscribe(pricesSent => {
            this.refresh();
            this._isDeleting = false;
          },
          (e) => {
            this.notificationService.error('Something wrong happened...', `Backend returned code ${e.status} : ${e.error?.error_description}`);
            this._isDeleting = false;
          });
      }
    });
  }

  handleError(error: HttpErrorResponse)  {
    console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
    this.notificationsService.error('Something wrong happened...', `Backend returned code ${error.status}`);
    return EMPTY;
  }

  get prices(): Price[] {
    return this._prices;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isDeleting(): boolean {
    return this._isDeleting;
  }
}
