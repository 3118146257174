import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SelectItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { TypeState } from 'src/app/state/action/state';
import { Type } from '../../model/type';
import { TypeSelect } from '../../state/actions';
import { takeUntil } from 'rxjs/operators';
import { EntityIdSelect } from '../../state/actions';
import { EntityIdentifier } from 'src/app/model/entity-identifier';

@Component({
  selector: 'app-entity-picker-type',
  templateUrl: './entity-picker-type.component.html',
})
export class EntityPickerTypeComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  @Input()
  typeAvailable : Type[];
  @Select(TypeState) state$: Observable<Type>;
  selectedType: Type;
  types: SelectItem[];

  constructor(private store: Store) {
  }

  onChange(type: Type) {
    if (type) {
      this.store.dispatch(new TypeSelect(type));

      const queryString = window.location.search;
      const entity_code = new URLSearchParams(queryString).get('entity_code');

      this.store.dispatch(new EntityIdSelect(<EntityIdentifier> { code: Number(entity_code), type: type.toString() }));
    }
  }

  ngOnInit(): void {
    this.types = this.typeAvailable.map(value => ({ label: value.toString(), value }));
    this.state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(type => {
      this.selectedType = type
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
