<p-panel *appUserCan="['MANAGE_SUPPORT_PRICE_UI','SAVE_SAP_PRICE']; operation 'OR'" class='p-m-2' header='Actions'>
  <div class='p-toolbar-group-left flex'>

    <div *appHasRole="['ADMIN']" class='flex flex-column'>
      <div class='flex'  *ngIf="(engine$| async) === 'Retail' || (engine$| async) === 'SecondLife'">
        <button (click)='replay()' class='p-button-raised p-button-info mr-2' label='Replay' pButton pRipple
                type='button'></button>
        <div class='mr-2' *ngIf='!isSecondLifeId() '>
          <p-calendar [(ngModel)]='replayDate' [showTime]='true' inputId='time'></p-calendar>
        </div>
        <div *appUserCan="['MANAGE_SUPPORT_PRICE_UI']; operation 'OR'">
          <button (click)='replayCessionPrice()' class='p-button-raised p-button-info mr-2' label='Force replay' pButton
                  pRipple type='button'></button>
        </div>
      </div>
      <div class='flex' *ngIf="(engine$| async) === 'Marketplace'">
        <button (click)='replay()' class='p-button-raised p-button-info mr-2' label='Replay' pButton pRipple
        type='button'></button>
      </div>
    </div>

    <div *ngIf="isEntityIdentifier()">
      <div *appUserCan="['MANAGE_SUPPORT_PRICE_UI']; operation 'OR'">
        <button (click)='resendInKafka()' class='p-button-raised p-button-info mr-2' label='Resend In Kafka' pButton
                pRipple type='button'></button>
      </div>
    </div>
  </div>
</p-panel>
