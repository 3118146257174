import { NrtModel } from '../../model/nrt-model';

export interface TimelinesToDisplay {
  nrtModels: NrtModel[];
  displayedModel: number;
}

export function timelines(nrtModels: NrtModel[]): TimelinesToDisplay {
  return {
    nrtModels,
    // the default displayed model is the last one
    displayedModel: nrtModels.length - 1,
  };
}

export function currentModel(timelines: TimelinesToDisplay): NrtModel {
  return timelines?.nrtModels[timelines.displayedModel];
}
