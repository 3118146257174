<form class='gantt_control'>
  <input (click)='collapse(false)' type='button' value='Collapse All'>
  <input (click)='collapse(true)' type='button' value='Expand All'>
  <ng-container *ngFor='let level of zoomConfig.levels'>
    <input (click)='zoom(level.name)' class='gantt_radio' id='{{level.name}}' name='scale' type='radio'
           value='{{level.name}}'>
    <label for='{{level.name}}'>{{level.name | beautify}} scale</label>
  </ng-container>
  <input (click)='zoomIn()' type='button' value='Zoom In'>
  <input (click)='zoomOut()' type='button' value='Zoom Out'>
</form>
