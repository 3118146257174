import { GeographicAreaType, toCode } from './geographic-area-type';
import { GeographicScope } from './geographic-scope';

export interface GeographicArea {
  id: number;
  geographic_scope: GeographicScope;
  parent?: number;
  name: string;
  tier_type_code: GeographicAreaType;
  tier_code: number;
  tier_sub_code: number;
  sales_organization_code: string;
  time_zone?: string;
  url?: string;
}

export function toTierConcat(geoArea: GeographicArea): string {
  return [toCode(geoArea.tier_type_code), geoArea.tier_code, geoArea.tier_sub_code].join('_');
}
