import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RpcService } from './api/rpc.service';
import { AppComponent } from './app.component';
import { ManageComponent } from './manage';
import { User } from './model/user';
import { RootComponent } from './root';
import { UploadComponent } from './upload';
import { DownloadComponent } from './download';
import { LogicalOperator } from './util/logic/logical-operator';

function requirePermission(permissions: string[], operator: LogicalOperator): CanActivateFn {
  return (ars: ActivatedRouteSnapshot, rss: RouterStateSnapshot): Observable<boolean> => {
    const rpcService = inject(RpcService);

    return rpcService.getCurrentUser().pipe(
      map((user) => {
        return new User(user).checkPermissions(permissions, operator);
      })
    );
  }
}

const routes: Routes = [
  {
    path: '', component: RootComponent,
    title: '',
    children: [
      {
        path: '',
        title: '',
        component: AppComponent
      },

      {
        path: 'upload',
        component: UploadComponent,
        title: '',
        canActivate: [
          requirePermission(['SAVE_MULTIPLE_PRICE_USING_SUPPORT_UI'], 'OR')
        ]
      },
      {
        path: 'download',
        component: DownloadComponent,
        title: '',
        canActivate: [
          requirePermission(['EXPORT_PRICES'], 'OR')
        ]
      },
      {
        path: 'manage',
        component: ManageComponent,
        title: '',
        canActivate: [
          requirePermission(['RETAIL_READ_OWN_REQUESTED_PRICES', 'RETAIL_READ_ALL_REQUESTED_PRICES_SO', 'RETAIL_READ_ALL_REQUESTED_PRICES_STORE', 'RETAIL_READ_ALL_REQUESTED_PRICES_ECOM'], 'OR')
        ]
      }
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}


