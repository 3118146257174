import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { RpcService } from '../../api/rpc.service';
import { GeographicArea, toTierConcat } from '../../model/geographic-area';
import { GeoAreaFindFirst, GeoAreaSearch, GeoAreaSelect } from '../actions';

@Injectable()
@State<GeographicArea[]>({ name: 'geoArea', defaults: []})
export class GeoAreaFoundState {

  constructor(private rpcService: RpcService, private store: Store) {
  }

  @Action(GeoAreaSearch)
  search(ctx: StateContext<GeographicArea[]>, search: GeoAreaSearch) {
    return this.rpcService.searchGeographicArea(search.query)
    .pipe(map(geoAreas => geoAreas.map(rename)), tap(ctx.setState));
  }

  @Action(GeoAreaFindFirst)
  searchAndKeepFirst(ctx: StateContext<GeographicArea[]>, search: GeoAreaFindFirst) {
    return this.rpcService.searchGeographicArea(search.query)
    .pipe(map(geoAreas => {
      if (geoAreas.length > 0) {
        this.store.dispatch(new GeoAreaSelect(geoAreas[0]));
      }
      return geoAreas;
    }));
  }

}

function rename(geoArea: GeographicArea): GeographicArea {
  geoArea.name = geoArea.name + '  ' + toTierConcat(geoArea);
  return geoArea;
}
