export interface CsvUploadPrices {
  csv_upload_line_reports: CsvLineReport[];
  errors: string[];
  hasErrors: boolean;
  hasWarning: boolean;
}

export interface CsvLineReport {
  line_index: number;
  columns: CsvPriceColumn[];
  status: CSV_STATUS;
  errors: string[];
  warnings: string[];
  geographic_area: any;
}

export interface CsvPriceColumn {
  raw_value: string;
  applied_value?: string;
  errors: string[];
  warning: string[];
}

export enum CSV_STATUS {
  VALIDATION_KO = 'VALIDATION_KO',
  VALIDATION_OK = 'VALIDATION_OK',
  SIMULATION_KO = 'SIMULATION_KO',
  SIMULATION_OK = 'SIMULATION_OK',
  SIMULATION_WARNING = 'SIMULATION_WARNING',
  INTEGRATION_OK = 'INTEGRATION_OK',
  INTEGRATION_KO = 'INTEGRATION_KO'
}
