import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isEntityIdentifier } from 'src/app/model/entity-id';
import { EntityIdentifier } from 'src/app/model/entity-identifier';
import { QueryContextState } from '../../state/action/state';
import { EntityIdSelect } from '../../state/actions';
import { QueryContext } from '../../state/model/entity-geo-area';
import { TypeState } from '../../state/action/state';
import { Type } from '@angular/compiler';

@Component({
  selector: 'app-entity-picker-retail',
  templateUrl: './entity-picker-retail.component.html',
})
export class EntityPickerRetailComponent implements OnInit {
  @Select(QueryContextState) state$: Observable<QueryContext>;
  @Select(TypeState) type$: Observable<Type>;
  debounceTime = 300;
  displayedCode;
  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  private type: Type;

  constructor(private store: Store) {
    this.state$.subscribe(newValue => this.displayedCode = isEntityIdentifier(newValue.entityId) ? newValue.entityId.code : undefined);
    this.type$.subscribe(
      typeValue => {
        this.type = typeValue;
      }
    )
  }

  changed(stringCode: string) {
    let newCode = !!stringCode ? +stringCode : undefined;
    this.store.dispatch(new EntityIdSelect(<EntityIdentifier> { code: newCode, type: this.type.toString() }));
  }

  inputChanged(text: any) {
    this.modelChanged.next(text);
  }

  ngOnInit(): void {
    this.subscription = this.modelChanged
    .pipe(debounceTime(this.debounceTime))
    .subscribe(event => this.changed(event));
  }
}
