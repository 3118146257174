import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { CsvExportPrice } from '../model/csv-export-prices';
import { CsvUploadPrices } from '../model/csv-upload-prices';
import { Price } from '../model/price';
import { env } from './env';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ManagePriceService {

  private url= env.apiUrl + '/v3/retail/prices/';

  constructor(private http: HttpClient,
              private notificationsService: NotificationsService) {}
  getPrices(pageSize: number, pageNumber: number, geographicAreaType: string, entityType: string, priceId: string, entityCode: string, tierCode: string, userId: string): Observable<Price[]> {
    var url = this.url + 'search?page_size=' +
      pageSize + '&page_number=' +
      pageNumber + '&geographic_area_type=' +
      geographicAreaType + '&entity_type=' +
      entityType;

    url = this.updateUrlWithParam(url, userId, tierCode, priceId, entityCode);
    return this.http.get<Price[]>(url);
  }

  updateUrlWithParam(url : string, userId: string, tierCode: string, priceId: string, entityCode: string){
    if(userId != null && userId!=''){
      url += '&user_id='+userId
    }
    if(entityCode != null && entityCode!=''){
      url += '&entity_code='+entityCode
    }
    if(tierCode != null && tierCode!=''){
      url += '&tier_code='+tierCode
    }
    if(priceId != null && priceId!=''){
      url += '&id='+priceId
    }
    return url;
  }
  getCounts(geographicAreaType: string, entityType: string, priceId: string, entityCode: string, tierCode: string, userId: string): Observable<number> {
    var url = this.url + 'count?geographic_area_type='+
      geographicAreaType + '&entity_type=' +
      entityType;

    url =this.updateUrlWithParam(url, userId, tierCode, priceId, entityCode);

    return this.http.get<number>(url)
    .pipe(catchError(e => this.handleError(e)));
  }

  delete(id: string) : Observable<string>{
    return this.http.delete<string>(this.url + id);
  }

  handleError(error: HttpErrorResponse)  {
    console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
    this.notificationsService.error('Something wrong happened...', `Backend returned code ${error.status}`);
    return EMPTY;
  }

}

