import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import { Engine } from './model/engine';
import { EntityIdentifier } from './model/entity-identifier';
import { PartnerId } from './model/partner-id';
import { Type } from './model/type';
import { EngineState, EntityIdNotFoundState, TypeState } from './state/action/state';
import {
  EngineSelect,
  EntityIdSelect,
  GeoAreaFindFirst,
  selectTimelines,
  SkuSelect,
  TimelinesToDisplayPaginate,
  TypeSelect
} from './state/actions';
import { TimelinesToDisplay } from './state/model/timelines-to-display';
import { handleDragAndDrop } from './ui/drag-and-drop/drag-and-drop';
import { beautify } from './util/string';
import { SecondLifeId } from './model/second-life-id';
import {UserMetricService} from './api/user-metric.service';
import {UserSupportAction} from './model/metric/user-support-action';

// @ts-ignore
@Component({
  templateUrl: './app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  @Select() timelines$: Observable<TimelinesToDisplay>;
  @Select(EntityIdNotFoundState) notFound$: Observable<boolean>;
  @ViewChild('dropZone', { static: true })
  dropZone!: ElementRef;
  @Select(EngineState) engine$: Observable<Engine>;
  @Select(TypeState) type$: Observable<Type>;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private userMetricService : UserMetricService) {
  }

  subs = new Subscription();

  isEmpty(model: TimelinesToDisplay) {
    return (model?.nrtModels?.length ?? 0) === 0;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs = this.userMetricService.userIsAuthenticated$.subscribe((isAuth: boolean) => {
      if(isAuth) {
        this.userMetricService.sendMetrics(UserSupportAction.SEARCH);
      }
    });
    handleDragAndDrop(this.dropZone, parsed => this.store.dispatch(selectTimelines(parsed)));
    this.route.queryParams.subscribe(params => {
      if (params['engine']) {
        const requestedEngine = Engine[beautify(<string> params['engine'].toLowerCase())];

        if (params['entity_code'] && params['engine']) {
          if (Number.isSafeInteger(+params['entity_code']) && requestedEngine === Engine.Retail) {
            var type = (params['type']) ? params['type'] : 'MODEL';
            this.store.dispatch(new EntityIdSelect(<EntityIdentifier> { code: +params['entity_code'], type: type }));
          } else if (params['entity_code'] !== '' && requestedEngine === Engine.Marketplace) {
            this.store.dispatch(new EntityIdSelect(<PartnerId> { partner_id: params['entity_code'] }));
          } else if (requestedEngine === Engine.Secondlife) {
            this.store.dispatch(new EntityIdSelect(<SecondLifeId> { second_life_id: params['entity_code'] }));

          }
        }

        if (params['tiers_concat'] && params['tiers_concat'].match(/(\d+)_(\d+)_(\d+)/)) {
          this.store.dispatch(new GeoAreaFindFirst(params['tiers_concat']));
        }

        if (params['sku_id']) {
          const requestedType = Type[beautify(<string> Type.Sku.toLowerCase())]

          this.store.dispatch(new TypeSelect(requestedType))
          this.store.dispatch(new SkuSelect(params['sku_id']));
        }

        if (requestedEngine != null) {
          this.store.dispatch(new EngineSelect(requestedEngine));
        }
        if (params['type']) {
          const requestedType = Type[beautify(<string> params['type'].toLowerCase())]
          if (requestedType != null) {
            this.store.dispatch(new TypeSelect(requestedType))
          }
        }
      } else {
        this.store.dispatch(new EngineSelect(Engine.Retail));
      }

    });
  }

  paginate(page: number) {
    this.store.dispatch(new TimelinesToDisplayPaginate(page));
  }
}
