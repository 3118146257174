import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { ApiError } from '../model/api-error';
import { Severity } from '../model/log-severity';
import { beautify } from '../util/string';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private messageService: MessageService) {
  }

  warn(title: string, description: string, sticky: boolean = true) {
    this.notify('warn', title, description, sticky);
  }

  info(title: string, description: string, sticky: boolean = true) {
    this.notify('info', title, description, sticky);
  }

  error(title: string, description: string, sticky: boolean = true) {
    this.notify('error', title, description, sticky);
  }

  success(title: string, description: string, sticky: boolean = false) {
    this.notify('success', title, description, sticky);
  }

  notify(severity: Severity, title: string, description: string, sticky: boolean) {
    const message: Message = {
      severity: severity,
      summary: beautify(title),
      detail: description,
      sticky: sticky
    };

    this.messageService.add(message);
  }

  notifyApiError(apiError: ApiError) {
    this.messageService.add(this.apiErrorToToast(apiError));
  }

  private apiErrorToToast(apiError: ApiError): Message {
    const errorToStatus = error => ({ BAD_REQUEST: 'warn', INTERNAL_SERVER_ERROR: 'error' })[error] ?? 'warn';
    const severity = errorToStatus(apiError.error);
    return {
      severity,
      summary: beautify(severity),
      detail: apiError.error_description.join(', ')
    };
  }
}
