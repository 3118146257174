import sortBy from 'lodash-es/sortBy';

export type UnaryOperator<T> = (thing: T) => T;

export type Consumer<T> = (thing: T) => any;

export const identity = t => t;

export interface Dictionary<T> {
  [key: string]: T;
}

export interface MultiMap<T> {
  [key: string]: T[];
}

export function filterUndef<T>(ts: (T | undefined)[]): T[] {
  return ts.filter((t: T | undefined): t is T => !!t);
}

export const isEmpty = (array?: any[]) => !array || array.length === 0;

export function multimap<T, V>(array: T[], keyGetter: ((val: T) => string), valueGetter: ((val: T) => V)): MultiMap<V> {
  return array.reduce((prev, curr) => {
    const key = keyGetter(curr);
    prev[key] = (prev[key] || []).concat([valueGetter(curr)]);
    return prev;
  }, {});
}

export function valueIf(condition: boolean, value: string) {
  return condition ? value : '';
}

export function update<T>(modifier: Consumer<T>): UnaryOperator<T> {
  return t => {
    const cloned = clone(t);
    modifier(cloned);
    return cloned;
  };
}

export function createDataTrees<T, ID>(dataset: T[], idGetter: ((val: T) => ID), parentIdGetter: ((val: T) => ID | undefined)): Tree<T>[] {
  const hashTable = Object.create(null);
  dataset.forEach(t => hashTable[idGetter(t)] = { data: t, children: [] });

  const dataTree = [];
  dataset.forEach(t => {
    const parentId = parentIdGetter(t);
    const id = idGetter(t);

    if (parentId) {
      hashTable[parentId].children.push(hashTable[id]);
    } else {
      dataTree.push(hashTable[id]);
    }
  });
  return dataTree;
}

export type Tree<T> = {
  data: T;
  children: Tree<T>[];
}

export function multipleSortBy<T>(array: T[], ...sortMethods: Consumer<T>[]) {
  return sortMethods.reverse()
  .reduce((sortedArray, consumer) => sortBy(sortedArray, consumer), array);
}

export function clone<T>(thing: T): T {
  return JSON.parse(JSON.stringify(thing));
}

function* uniqueIdGenerator() {
  let num = 1;
  while (true) {
    yield num;
    num = num + 1;
  }
}

const privateUniqueId = uniqueIdGenerator();

export const uniqueId = () => privateUniqueId.next().value as number;

