import { Pipe, PipeTransform } from '@angular/core';
import { NrtModel } from '../../model/nrt-model';
import { currentModel, TimelinesToDisplay } from '../../state/model/timelines-to-display';

@Pipe({
  name: 'currentModel',
  pure: true
})
export class CurrentModelPipe implements PipeTransform {

  transform(value: TimelinesToDisplay): NrtModel {
    return currentModel(value);
  }
}
