import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isSecondLifeId } from 'src/app/model/entity-id';
import { SecondLifeId } from '../../model/second-life-id';
import { QueryContextState } from '../../state/action/state';
import { EntityIdSelect } from '../../state/actions';
import { QueryContext } from '../../state/model/entity-geo-area';

@Component({
  selector: 'app-entity-picker-second-life',
  templateUrl: './entity-picker-second-life.component.html',
})
export class EntityPickerSecondLifeComponent implements OnInit, OnDestroy {
  @Select(QueryContextState) state$: Observable<QueryContext>;
  debounceTime = 300;
  displayedCode;
  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  private secondLifeIdSubscription: Subscription;

  constructor(private store: Store) {
    this.secondLifeIdSubscription = this.state$.subscribe(newValue =>
      this.displayedCode = isSecondLifeId(newValue.entityId) ? newValue.entityId.second_life_id : '');
  }

  changed(text: string) {
    this.store.dispatch(new EntityIdSelect(<SecondLifeId> { second_life_id: text }));
  }

  inputChanged(text: string) {
    this.modelChanged.next(text);
  }

  ngOnInit(): void {
    this.subscription = this.modelChanged
    .pipe(debounceTime(this.debounceTime),)
    .subscribe(event => this.changed(event));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.secondLifeIdSubscription?.unsubscribe();
  }
}
