import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.css']
})
export class ScrollToTopComponent implements OnInit {
  windowScrolled: boolean;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const currentScroll = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop;
    this.windowScrolled = currentScroll > 200 ? true : false;
  }
  
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {}
}

