import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import countryCodeEmoji from 'country-code-emoji';
import { TreeNode } from 'primeng/api';
import {Observable, of} from 'rxjs';
import { distinct, map, mergeMap } from 'rxjs/operators';
import { RpcService } from '../../api/rpc.service';
import { GeographicArea, toTierConcat } from '../../model/geographic-area';
import { QueryContextState } from '../../state/action/state';
import { QueryContext } from '../../state/model/entity-geo-area';
import { createDataTrees, Tree } from '../../util/object';

@Component({
  selector: 'app-geo-hierarchy-display',
  templateUrl: './geo-hierarchy-display.component.html'
})
export class GeoHierarchyDisplayComponent implements OnInit {
  @Select(QueryContextState) state$: Observable<QueryContext>;
  geoAreaHierachy$!: Observable<TreeNode<GeographicArea>[]>;

  constructor(private rpcService: RpcService) {
  }

  openURL(geo: GeographicArea): void {
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set("tiers_concat", toTierConcat(geo));

    window.open("//" + window.location.host + '/?' + queryParams.toString(), "_blank");

  }

  ngOnInit(): void {
    this.geoAreaHierachy$ = this.state$.pipe(
      distinct(),
      mergeMap(state => {
        if (this.hasGeoAreadId(state)) {
          return this.rpcService.getGeoHierarchy(state.geoArea.id);
        }
        return of();
      }),
      map(buildTree));
  }

  private hasGeoAreadId(state: QueryContext): boolean {
    return !!state?.geoArea?.id;
  }
}

function label(geo: GeographicArea): string {
  return soToEmoji(geo.sales_organization_code) + ' ' + geo.name + ' ' + toTierConcat(geo);
}

function buildTree(geoAreas: GeographicArea[]): TreeNode[] {
  return createDataTrees(geoAreas, geo => geo.id, geo => geo.parent).map(geoAreaToTreeNode);
}

function tooltip(geo: GeographicArea): string {
  if (geo.time_zone !== undefined) {
    const currentDate = new Date().toLocaleString('en-US', { timeZone: geo.time_zone });
    return `Timezone: ${geo.time_zone}
    Current date: ${currentDate}
    Country code: ${geo.sales_organization_code}`;
  }
  return 'No timezone';
}

function geoAreaToTreeNode(geoAreaTree: Tree<GeographicArea>): TreeNode<GeographicArea> {
  const geo = geoAreaTree.data;
  const icon = 'pi ' + {
    WORLD: 'pi-globe',
    SALES_ORGANIZATION: 'pi-flag',
    REGION: 'pi-sitemap',
    STORE: 'pi-shopping-cart',
    ECOMMERCE: 'pi-cloud'
  }[geo.tier_type_code];
  const time_zone = tooltip(geo);
  return {
    label: label(geo),
    data: { ...geo, time_zone},
    icon,
    expanded: geoAreaTree.children.length > 0,
    children: geoAreaTree.children.map(child => geoAreaToTreeNode(child)).sort((a, b) => a.data?.tier_code - b.data?.tier_code),
    type: 'url'
  };
}

// safely parse Sales Organization code to emoji
function soToEmoji(soCode?: string): string {
  if (soCode == undefined) {
    return '🌎'; // only WORLD should have it's SO code undefined
  }
  try {
    return countryCodeEmoji(soCode);
  } catch (e) {
    return `⚠️ ${soCode} ⚠️`;
  }
  
}
