import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isPartnerId } from 'src/app/model/entity-id';
import { PartnerId } from 'src/app/model/partner-id';
import { QueryContextState } from '../../state/action/state';
import { EntityIdSelect } from '../../state/actions';
import { QueryContext } from '../../state/model/entity-geo-area';

@Component({
  selector: 'app-entity-picker-marketplace',
  templateUrl: './entity-picker-marketplace.component.html',
})
export class EntityPickerMarketplaceComponent implements OnInit {
  @Select(QueryContextState) state$: Observable<QueryContext>;
  debounceTime = 300;
  displayedCode;
  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;

  constructor(private store: Store) {
    this.state$.subscribe(newValue => this.displayedCode = isPartnerId(newValue.entityId) ? newValue.entityId.partner_id : '');
  }

  changed(text: string) {
    this.store.dispatch(new EntityIdSelect(<PartnerId> { partner_id: text }));

  }

  inputChanged(text: string) {
    this.modelChanged.next(text);
  }

  ngOnInit(): void {
    this.subscription = this.modelChanged
    .pipe(debounceTime(this.debounceTime),)
    .subscribe(event => this.changed(event));
  }
}
