import { Component, OnInit } from '@angular/core';
import { isEqual } from 'lodash';
import isEmpty from 'lodash-es/isEmpty';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { NotificationsService } from '../api/notifications.service';
import { UploadPriceService } from '../api/upload-price,service';
import { UploadPriceHistoryService } from '../api/upload-price-history.service';
import { CsvExportPrice } from '../model/csv-export-prices';
import { CsvUploadPricesHistory } from '../model/csv-upload-price-history';
import { CSV_STATUS, CsvLineReport, CsvPriceColumn, CsvUploadPrices } from '../model/csv-upload-prices';
import { UPLOAD_PRICES_STEPS } from '../model/upload-prices-steps';
import {UserMetricService} from '../api/user-metric.service';
import {UserSupportAction} from '../model/metric/user-support-action';

@Component({
  templateUrl: 'upload.component.html',
  styleUrls: ['./upload.component.scss']})
export class UploadComponent implements OnInit {
  public readonly NUMBER_COLUMNS = 9;
  private _uploadPriceSteps: MenuItem[];
  private reader: FileReader;
  private csvFileContent: string;
  private _activeIndex: number;
  private _isLoading: boolean;
  private _verifiedPrices: CsvUploadPrices;
  private _sentPrices: CsvUploadPrices;
  private _hasErrors: boolean;
  private _hasWarnings: boolean;
  public showAll: string = 'Show mine';
  private _uploadHistory: CsvUploadPricesHistory[];

  constructor(private uploadPriceService: UploadPriceService,
              private notificationService: NotificationsService,
              private confirmationService: ConfirmationService,
              private uploadPriceHistoryService: UploadPriceHistoryService,
              private userMetricService: UserMetricService) {}

  ngOnInit(): void {
    this.userMetricService.sendMetrics(UserSupportAction.UPLOAD);
    this._hasErrors = false;
    this._isLoading = false;
    this._activeIndex = 0;
    this._uploadPriceSteps = UPLOAD_PRICES_STEPS;
    this.reader = new FileReader();
    this.reader.onload = () => {
      this.csvFileContent = '';
      if (!!this.reader.result) {
        this.csvFileContent += this.reader.result;
        this.simulatePrices();
      }
    };

    this.refresh = this.refresh.bind(this);
    this.refresh();
  }

  readFile($event: any) {
    this.reader.readAsText($event.files[0]);
  }

  isUploadFileStep() {
    return this.activeIndex === 0;
  }

  isVerifyPriceStep() {
    return this.activeIndex === 1;
  }

  isPriceSentStep() {
    return this.activeIndex === 2;
  }

  hasError(lineItem: CsvLineReport) {
    return !isEmpty(lineItem.errors)
      || !isEmpty(lineItem.columns.flatMap(column => column.errors));
  }

  hasWarning(lineItem: CsvLineReport) {
    return !isEmpty(lineItem.warnings)
      || !isEmpty(lineItem.columns.flatMap(column => column.warning));
  }

  computeError(lineItem: CsvLineReport): string[] {
    return lineItem.errors.concat(lineItem.columns.flatMap(column => column.errors));
  }

  computeWarning(lineItem: CsvLineReport): string[] {
    return lineItem.warnings.concat(lineItem.columns.flatMap(column => column.warning));
  }


  getStatusClass(lineItem: CsvLineReport) {
    if (lineItem.status === CSV_STATUS.SIMULATION_KO
      || lineItem.status === CSV_STATUS.VALIDATION_KO
      || lineItem.status === CSV_STATUS.INTEGRATION_KO) {
      return 'rejected'
    }
    if (lineItem.status === CSV_STATUS.SIMULATION_WARNING) {
      return 'warned';
    }
    return 'accepted'
  }

  getColumnClass(columnValue: CsvPriceColumn, lineItem: CsvLineReport) {
    if (this.isLineNotBeingValidated(lineItem)) {
      if (!isEmpty(columnValue.errors)) {
        return 'error';
      }
      if (!isEmpty(columnValue.warning)  || this.hasValueChanged(columnValue)) {
        return 'warning'
      }
    }
  }

  public isLineNotBeingValidated(lineItem: CsvLineReport) {
    return lineItem.status !== CSV_STATUS.VALIDATION_KO && lineItem.status !== CSV_STATUS.VALIDATION_OK;
  }

  goBack() {
    this._activeIndex = 0;
  }

  uploadPrices() {
    let message = '';
    if (this.verifiedPrices.hasWarning) {
      message = 'The prices you are uploading have warnings.';
    }

    this.confirmationService.confirm({
      message: message + ' Are you sure that you want to proceed?',
      header: 'Upload prices',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._isLoading = true;
        this.uploadPriceService.uploadPrices(this.csvFileContent).subscribe(pricesSent => {
          this._activeIndex = 2;
          this._sentPrices = pricesSent;
          this._isLoading = false;
        },
        () => {},
          () => {
          this._isLoading = false;
        });
      }
    });
  }

  hasValueChanged(column: CsvPriceColumn): boolean {
    return !isEqual(column.raw_value, column.applied_value);
  }

  missingColumn(lineItem: CsvLineReport) {
    return lineItem.columns.length < this.NUMBER_COLUMNS;
  }

  getMissingColumns(lineItem: CsvLineReport) {
    return [...Array(this.NUMBER_COLUMNS - lineItem.columns.length).keys()];
  }

  isFileContentValid() {
    return isEmpty(this.verifiedPrices.errors);
  }

  getUploadButtonClass() {
    return this.hasWarnings ? 'p-button-raised p-button-warning'
      : 'p-button-raised p-button-success';
  }

  private simulatePrices() {
    this._isLoading = true;
    this.uploadPriceService.simulatePrices(this.csvFileContent)
      .subscribe(verifiedPrices => {
        this._verifiedPrices = verifiedPrices;
        this._activeIndex = 1;
        this._hasErrors = verifiedPrices.hasErrors;
        this._hasWarnings = verifiedPrices.hasWarning;
      },
        () => {},
        () => {
        this._isLoading = false;
      });
  }

  downloadFile(){
    let link = document.createElement('a');
    link.download = 'upload-csv-structure.csv';
    link.href = 'assets/upload/structure-upload.csv';
    link.click();
  }

  buildTimeLineURL(priceSent: CsvLineReport) {
    const itemCode = priceSent.columns[1].raw_value;
    const tiersType = priceSent.columns[2].raw_value;
    const tiersCode = priceSent.columns[3].raw_value;
    const tiersConcat = tiersType + '_' + tiersCode + '_' + tiersCode
    return `${window.location.origin}/?tiers_concat=${tiersConcat}&entity_code=${itemCode}&engine=Retail&type=Item`;
  }

  canBuildTimelineURL(priceSent: CsvLineReport): boolean {
    return !!priceSent.columns[1]?.raw_value
      && !!priceSent.columns[2]?.raw_value
      && !!priceSent.columns[3]?.raw_value;
  }
  get uploadPriceSteps(): MenuItem[] {
    return this._uploadPriceSteps;
  }

  get activeIndex(): number {
    return this._activeIndex;
  }

  get verifiedPrices(): CsvUploadPrices {
    return this._verifiedPrices;
  }
  get hasErrors(): boolean {
    return this._hasErrors;
  }
  get hasWarnings(): boolean {
    return this._hasWarnings;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get sentPrices(): CsvUploadPrices {
    return this._sentPrices;
  }

  // History
  refresh(){
    this.uploadPriceHistoryService.getUpload(this.showAll == 'Show all')
    .subscribe(uploadHistory => {
      this._uploadHistory = uploadHistory;
    });
  }

  humanReadableDate(date: string) : string{
    return new Date(date).toLocaleString();
  }

  getCsv(lineItem: CsvUploadPricesHistory){
    this.uploadPriceHistoryService.getCsv(lineItem.id)
    .subscribe((data) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
      downloadLink.download = 'csv-uploaded-' + lineItem.creation_date + '.csv';
      downloadLink.click();
    });
  }

  getErrors(lineItem: CsvUploadPricesHistory){
    this.uploadPriceHistoryService.getErrors(lineItem.id)
    .subscribe((data) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
      downloadLink.download = 'error-uploaded-' + lineItem.creation_date + '.txt';
      downloadLink.click();
    });
  }

  toggleChange(){
    this.refresh();
  }

  delete(lineItem: CsvExportPrice){
    this.confirmationService.confirm({
      message: ' Are you sure that you want to delete this export?',
      header: 'Delete export',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.uploadPriceHistoryService.delete(lineItem.id).subscribe(pricesSent => {
            this.refresh();
          },
          (e) => {
            this.notificationService.error('Something wrong happened...', `Backend returned code ${e.status} : ${e.error?.error_description}`);
          });
      }
    });
  }

  get uploadHistory(): CsvUploadPricesHistory[] {
    return this._uploadHistory;
  }


}
