import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ExportPriceService } from '../api/export-price.service';
import { CsvExportPrice, ExportState } from '../model/csv-export-prices';
import { UPLOAD_PRICES_STEPS } from '../model/upload-prices-steps';
import { UploadPriceService } from '../api/upload-price,service';
import { NotificationsService } from '../api/notifications.service';
import { CSV_STATUS, CsvLineReport, CsvPriceColumn, CsvUploadPrices } from '../model/csv-upload-prices';
import isEmpty from 'lodash-es/isEmpty';
import { isEqual, now } from 'lodash';
import {UserSupportAction} from '../model/metric/user-support-action';
import {UserMetricService} from '../api/user-metric.service';

@Component({
  templateUrl: 'download.component.html',
  styleUrls: ['./download.component.scss']})
export class DownloadComponent implements OnInit {
  private reader: FileReader;
  private _isLoading: boolean;
  private _isDownloading: boolean;

  private _isDeleting: boolean;
  private _exports: CsvExportPrice[];
  private _sentPrices: CsvUploadPrices;
  public referenceDate: Date;
  public uploadForm: any;
  public showAll: string = 'Show mine';
  public exportFile;
  constructor(private exportPriceService: ExportPriceService,
              private notificationService: NotificationsService,
              private confirmationService: ConfirmationService,
              private userMetricService: UserMetricService) {}

  ngOnInit(): void {
    this.userMetricService.sendMetrics(UserSupportAction.DOWNLOAD);
    this._isLoading = false;
    this.refresh = this.refresh.bind(this);
    this.refresh();

    this.reader = new FileReader();
    this.reader.onload = () => {
      if (!!this.reader.result) {
        this.uploadPrices(''+ this.reader.result, this.exportFile.name, this.toLocateDate(this.referenceDate));
        this.uploadForm.clear();
      }
    };
  }

  toLocateDate(d: Date){
    return d.getFullYear() + '-' + ('0'+(d.getMonth()+1)).slice(-2) + '-' +
      ('0' + d.getDate()).slice(-2) + 'T' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2);
  }
  refresh(){
    this.exportPriceService.getExports(this.showAll == 'Show all')
    .subscribe(exports => {
      if(exports.find(csvExport => csvExport.export_state == 'IN_PROGRESS' || csvExport.export_state == 'PENDING') != null){
        setTimeout(this.refresh, 1000);
      }
      this._exports = exports;
    });
  }

  toggleChange(){
    this.refresh()
  }
  readFile($event: any, form: any) {
    this.exportFile = $event.files[0];
    this.uploadForm = form;
    this.reader.readAsText(this.exportFile);
  }

  numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  public onUpload($event: any, form: any){
    this.exportFile = $event.files[0];
    this.uploadForm = form;
  }

  getPercentageCompletion(lineItem: CsvExportPrice): string{
    return Math.round((lineItem.line_done + lineItem.line_error + lineItem.line_not_found)/lineItem.line_count * 100) + '%';
  }

  getCsvIn(lineItem: CsvExportPrice){
    this._isDownloading = true;

    this.exportPriceService.getCsvIn(lineItem.id)
    .subscribe((data) => {

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
      this._isDownloading = false;
      const contentDisposition = data.headers.get('content-disposition');
      const fileName = 'csvIn-'+lineItem.name + '-'+lineItem.creation_date+'.csv';
      downloadLink.download = fileName;
      downloadLink.click();

    });
  }

  downloadExemple(){
    let link = document.createElement('a');
    link.download = 'download-csv-structure.csv';
    link.href = 'assets/download/structure-download.csv';
    link.click();
  }

  getErrors(lineItem: CsvExportPrice){
    this._isDownloading = true;

    this.exportPriceService.getErrors(lineItem.id)
    .subscribe((data) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
      this._isDownloading = false;
      const fileName = 'error-'+lineItem.name + '-'+lineItem.creation_date+'.txt';
      downloadLink.download = fileName;
      downloadLink.click();

    });
  }
  getCsvOut(lineItem: CsvExportPrice){
    this._isDownloading = true;

    this.exportPriceService.getCsvOut(lineItem.id)
    .subscribe((data) => {

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));

      this._isDownloading = false;
      const contentDisposition = data.headers.get('content-disposition');
      const fileName = 'csvOut-'+lineItem.name + '-'+lineItem.creation_date+'.csv';
      downloadLink.download = fileName;
      downloadLink.click();

    });
  }

  humanReadableDate(date: string) : string{
    return new Date(date).toLocaleString();
  }

  delete(lineItem: CsvExportPrice){

    this.confirmationService.confirm({
      message: ' Are you sure that you want to delete this export?',
      header: 'Delete export',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._isDeleting = true;
        this.exportPriceService.delete(lineItem.id).subscribe(pricesSent => {
            this.refresh();
            this._isDeleting = false;
          },
          (e) => {
            this.notificationService.error('Something wrong happened...', `Backend returned code ${e.status} : ${e.error?.error_description}`);
            this._isDeleting = false;
          });
      }
    });

  }
  download(dataurl, filename) {
    const link = document.createElement('a');
    link.href = dataurl;
    link.download = filename;
    link.click();
  }

  getProcessingTime(startDateString: string, endDateString: string): string{
    if(startDateString == null)
      return 'Waiting...';

    var startDate = new Date(Date.parse(startDateString));
    var endDate = endDateString == null ? new Date(): new Date(Date.parse(endDateString));

    var duration = new Date(0)
    duration.setSeconds((endDate.getTime() - startDate.getTime()) / 1000)
    return duration.toISOString().substring(14 , 19);
  }

  uploadPrices(content: string, name: string, referenceDate: string) {
    this.confirmationService.confirm({
      message: ' Are you sure that you want to proceed with this file?',
      header: 'Upload export',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._isLoading = true;
        this.exportPriceService.sendExportPrices(content, name, referenceDate)
        .subscribe(pricesSent => {
          this.refresh();
          this._isLoading = false;
        },
        (e) => {
          console.log(e);
          this.refresh();
          this.notificationService.error('Something wrong happened...', `Backend returned code ${e.status} : ${e.error?.error_description}`);
          this._isLoading = false;
        });
      }
    });
  }

  get exports(): CsvExportPrice[] {
    return this._exports;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isDeleting(): boolean {
    return this._isDeleting;
  }

  get isDownloading(): boolean{
    return this._isDownloading;
  }

  get sentPrices(): CsvUploadPrices {
    return this._sentPrices;
  }
}
