import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { CsvExportPrice } from '../model/csv-export-prices';
import { CsvUploadPricesHistory } from '../model/csv-upload-price-history';
import { CsvUploadPrices } from '../model/csv-upload-prices';
import { env } from './env';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import { catchError } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class UploadPriceHistoryService {

  private url= env.apiUrl + '/v4/prices/uploads';

  constructor(private http: HttpClient,
              private notificationsService: NotificationsService) {}

  getUpload(showAll: boolean): Observable<CsvUploadPricesHistory[]> {
    return this.http.get<CsvUploadPricesHistory[]>(this.url+'?showAll='+showAll)
    .pipe(catchError(e => this.handleError(e)));
  }

  getUploadById(uploadId: number): Observable<CsvUploadPricesHistory> {
    return this.http.get<CsvUploadPricesHistory>(this.url+'/'+uploadId)
    .pipe(catchError(e => this.handleError(e)));
  }

  getCsv(uploadId: number) : Observable<HttpResponse<Blob>>{
    return this.http.get<Blob>(this.url + '/' + uploadId + '/csv', { observe: 'response', responseType: 'blob' as 'json'});
  }

  getErrors(uploadId: number) : Observable<HttpResponse<Blob>>{
    return this.http.get<Blob>(this.url + '/' + uploadId + '/errors', { observe: 'response', responseType: 'blob' as 'json'});
  }

  delete(id: string) : Observable<string>{
    return this.http.delete<string>(this.url + '/' + id)
    .pipe(catchError(e => this.handleError(e)));
  }

  handleError(error: HttpErrorResponse)  {
    console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
    this.notificationsService.error('Something wrong happened...', `Backend returned code ${error.status}`);
    return EMPTY;
  }
}