<nav class='navbar navbar-expand-lg navbar-light bg-light'>
  <span>
    <a class='navbar-brand' href='/'>Masterprice</a>
    Worldwide selling price calculation
  </span>
</nav>
<p-confirmDialog></p-confirmDialog>
<p-toast position='top-center'></p-toast>
<div class='upload' *appUserCan="['EXPORT_PRICES']; operation 'OR';
notificationMessage 'You don\'t have the right to export prices'">
    <div class="upload-file">
      <ng-container>
        <div *ngIf="isDownloading">
          <div class="download-wait">
            <h1>Downloading file...</h1>
            <p-progressSpinner></p-progressSpinner>
          </div>
        </div>
        <div class="download-wait" *ngIf="isLoading">
          <h1>Uploading file...</h1>
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="download-wait" *ngIf="isDeleting">
          <h1>Deleting export...</h1>
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div>
          <h4 class="upload-title">Export prices</h4>
          <h5 class="upload-subtitle">Select a reference date and upload your file containing the prices to export</h5>
          <div class="notification">
            <span>The maximum file size is set to <b>1mb</b> (50 000 prices)</span> <small>(<a class='example-link' label="File example" icon="pi pi-download" (click)="downloadExemple()">Download file example</a>)</small><br/>
              <span>Once sent, the export will be done in background. You'll be able to retrieve result when done.</span><br/>
              <small>Exports will be kept during a week</small><br/>
              <small class='warning'>Past prices could be irrelevant as masterprice doesn't keep history </small><br />
              <small class='warning'><i class='pi pi-exclamation-triangle warning icon-state'></i>
                ️When uploading the file, duplicate lines are removed. <i class='pi pi-exclamation-triangle warning icon-state'></i></small>
          </div>

          <div>
            <b >Reference Date : </b>
              <p-calendar [(ngModel)]="referenceDate" inputId="reference_date" [showTime]="true"></p-calendar>

          </div>
          <div class="actions">

            <p-fileUpload #form [disabled] = 'referenceDate==null' [customUpload]="true" (uploadHandler)="readFile($event, form)" (onUpload)="onUpload($event, form)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" [multiple]="false" [maxFileSize]=1000000 >

            </p-fileUpload>
          </div>
        </div>

        <!-------------------------------------------------------- VERIFY STEP -------------------------------------------------------->
        <div>
          <div>
          <p-selectButton  *appUserCan="['READ_ALL_EXPORT_PRICES']" class="toggle" (onChange) = 'toggleChange()' [options]="['Show all','Show mine']" [(ngModel)]="showAll" ></p-selectButton>
          <br/>
          </div>
          <ng-container>
            <p-table [value]="exports">
              <ng-template pTemplate="header">
                <tr>
                  <th class="user">User</th>
                  <th class="exportDate">Export date</th>
                  <th class="name">Name</th>
                  <th class="referenceDate">Reference Date</th>
                  <th class="csvIn">Csv In</th>
                  <th class="stats">Counts</th>
                  <th class="csvOut">Csv Out</th>
                  <th class="processingTime">Processing time</th>
                  <th>Delete</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-lineItem>
                <tr>
                  <td class='user center'>{{lineItem.user_id}}</td>
                  <td class="center">{{ humanReadableDate(lineItem.creation_date) }}</td>
                  <td class="center">{{ lineItem.name }}</td>
                  <td class="center">{{ lineItem.reference_date }}</td>
                  <td class="center"><p-button styleClass="p-button-info" label="Input file" icon="pi pi-download" (click)="getCsvIn(lineItem)"></p-button> <br/>
                    <small>({{ numberWithSpaces(lineItem.line_count) }} lines)</small></td>
                  <td class='left'>
                    <span class='success'>{{ numberWithSpaces(lineItem.line_done) }} Ok</span>
                    <br/>
                    <span *ngIf="lineItem.line_not_found != 0" class='warning'>{{ numberWithSpaces(lineItem.line_not_found) }} not founds<br/></span>

                    <span *ngIf="lineItem.line_error != 0" class='error'>{{ numberWithSpaces(lineItem.line_error) }} errors <i class="download-error pi pi-download" (click)="getErrors(lineItem)"></i></span>

                  </td>
                  <td class="center">
                    <p-button *ngIf="lineItem.export_state == 'DONE'" styleClass="p-button-info" label="Export File" icon="pi pi-download" (click)="getCsvOut(lineItem)"></p-button>
                    <p-button *ngIf="lineItem.export_state != 'DONE' && lineItem.export_state != 'ERROR'" [disabled]="true" styleClass="p-button-info" label="{{getPercentageCompletion(lineItem)}}" icon="pi pi-spin pi-spinner"></p-button>
                    <p-button *ngIf="lineItem.export_state == 'ERROR'" [disabled]="true" styleClass="p-button-danger" label="Error" icon="pi pi-exclamation-triangle"></p-button>
                  </td>
                  <td class="center">
                    <i *ngIf="lineItem.export_state == 'DONE'" class='pi pi-check-circle success icon-state'></i>
                    <i *ngIf="lineItem.export_state == 'IN_PROGRESS'" class='pi pi-spin pi-spinner info icon-state'></i>
                    {{getProcessingTime(lineItem.start_date, lineItem.end_date)}}
                  </td>
                  <td><p-button styleClass="p-button-danger" label="Delete" icon="pi pi-trash" (click)="delete(lineItem)"></p-button> </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </div>

        <div class="home">
          <p-button styleClass="back p-button-secondary" label="Home" icon="pi pi-home" routerLink=""></p-button>
        </div>
      </ng-container>
    </div>

</div>
<div class='upload' *appUserCannot="['EXPORT_PRICES']; operation 'OR';">
  <p-messages [severity]= "'error'">
    <ng-template>
      <div>
        <h1>Not authorize</h1>
        <br>You don't have the permissions to export prices
        <br>
        <button class="p-button-raised mr-2" pButton pRipple type='button' routerLink="">Back home</button>
      </div>
    </ng-template>
  </p-messages>
</div>
