import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SelectItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { EngineState } from 'src/app/state/action/state';
import { Engine } from '../../model/engine';
import { EngineSelect } from '../../state/actions';

@Component({
  selector: 'app-engine-picker',
  templateUrl: './engine-picker.component.html'
})
export class EnginePickerComponent implements OnInit, OnDestroy {
  subscription?: Subscription;
  @Select(EngineState) state$: Observable<Engine>;
  selectedEngine: Engine;
  engines: SelectItem[] = [Engine.Retail, Engine.Marketplace, Engine.Secondlife].map(value => ({ label: value.toString(), value }));

  constructor(private store: Store) {
  }

  onChange(selectedEngine: Engine) {
    if (selectedEngine) {
      this.store.dispatch(new EngineSelect(selectedEngine));
    }
  }

  ngOnInit(): void {
    this.subscription = this.state$.subscribe(engine => this.selectedEngine = engine);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
