import { beautify } from '../util/string';
import { EntityIdentifier } from './entity-identifier';
import { PartnerId } from './partner-id';
import { SecondLifeId } from './second-life-id';

export type EntityId = EntityIdentifier | PartnerId | SecondLifeId

export function toEntityIdString(entityId: EntityId): string {
  if (isEntityIdentifier(entityId)) {
    return `${beautify(entityId.type)} ${entityId.code}`;

  } else if (isPartnerId(entityId)) {
    return `${entityId.partner_id}`;
  } else if (isSecondLifeId(entityId)) {
    return `${entityId.second_life_id}`;
  }

  return 'Cannot deserialize properly: ' + JSON.stringify(entityId);
}

export function isEntityIdentifier(maybeEI: EntityId): maybeEI is EntityIdentifier {
  const entityIdentifier = maybeEI as EntityIdentifier;
  return !!entityIdentifier && !!entityIdentifier.code &&  !!entityIdentifier.type;
}

export function isPartnerId(maybeEI: EntityId): maybeEI is PartnerId {
  const partnerId = maybeEI as PartnerId;
  return !!partnerId?.partner_id;
}

export function isSecondLifeId(maybeEI: EntityId): maybeEI is SecondLifeId {
  const secondLifeId = maybeEI as SecondLifeId;
  return  !!secondLifeId?.second_life_id;
}
