<p-autoComplete (completeMethod)='search($event)'
                [formControl]="geoAreaForm"
                [dropdownMode]='"current"'
                [dropdown]='true'
                [minLength]='1'
                [size]='26'
                [suggestions]='found$ | async'
                ariaLabel='Geographic area'
                field='name'
                placeholder='Geographic area'>
</p-autoComplete>
