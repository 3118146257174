import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import intersection from 'lodash-es/intersection';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../model/user';
import { QueryContextState } from '../../state/action/state';
import { QueryContext } from '../../state/model/entity-geo-area';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  @Select(QueryContextState) state$: Observable<QueryContext>;
  private destroyed$ = new Subject<void>();
  private currentRole: string[] = [];
  private expectedRoles: string[] = [];
  private operator: operator = 'AND';

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
  }

  @Input()
  set appHasRole(role: string[]) {
    this.expectedRoles = role;
    this.updateView();
  }

  @Input()
  set appHasRoleOperation(op: operator) {
    this.operator = op;
  }

  ngOnInit(): void {
    this.state$.pipe(takeUntil(this.destroyed$))
      .subscribe(state => this.updateView(state.user));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private updateView(user?: User) {
    const newRole = user?.roles ?? [];
    if (isEqual(this.currentRole, newRole)) {
      return;
    }
    this.currentRole = newRole;
    if (this.checkRole()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkRole(): boolean {
    const roleIntersection = intersection(this.expectedRoles, this.currentRole);
    return this.operator === 'OR' ? !isEmpty(roleIntersection) : isEqual(roleIntersection, this.expectedRoles);
  }
}

type operator = 'OR' | 'AND';
