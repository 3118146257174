import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PricesIdsState } from 'src/app/state/action/state';
import { PriceIdSelect } from 'src/app/state/actions';

@Component({
  selector: 'app-entity-picker-price-id',
  templateUrl: './entity-picker-price-id.component.html',
})
export class EntityPickerPriceIdComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  @Select(PricesIdsState) state$: Observable<string[]>;
  selectedPriceId: string[];
  priceIds: string[];
  
  constructor(private store: Store) {
  }

  onChange(priceId: string) {
    if (priceId) {
      this.store.dispatch(new PriceIdSelect(priceId));
    }
  }

  ngOnInit(): void {
    this.state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(priceIds => this.priceIds = priceIds);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
