import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyJson',
})
export class PrettyJsonPipe implements PipeTransform {
 transform(obj: any): any {
     const tag = (match: any) => {
       let themeClass = 'number';
       const dateRegex = /^\"\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z)?\"$/g;
       if (dateRegex.test(match)) {
         themeClass = 'date';
       } else if (/^"/.test(match)) {
         if (/:$/.test(match)) {
           themeClass = 'key';
         } else {
           themeClass = 'string';
         }
       } else if (/true|false/.test(match)) {
         themeClass = 'boolean';
       } else if (/null/.test(match)) {
         themeClass = 'null';
       }
       return `<span class="${themeClass}">${match}</span>`;
     };

     return JSON.stringify(obj, undefined, 3)
       .replace(/&/g, '&amp;')
       .replace(/</g, '&lt;')
       .replace(/>/g, '&gt;')
       .replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, tag);
   }
}
