import { MenuItem } from 'primeng/api';

export const UPLOAD_PRICES_STEPS: MenuItem[] = [
  {
    label: 'Upload file',
  },
  {
    label: 'Verification and simulation',
  },
  {
    label: 'Prices sent',
  }
];
