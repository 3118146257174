<ng-container *ngIf='hierarchyLoaded'>
  <a (click)='refreshEntity(items[0]?.model_code, "Model")'>
    Model {{items[0]?.model_code}}
  </a>
  <br/>
  <a *ngFor="let item of items" (click)='refreshEntity(item.code, "Item")'>
      Item {{item.code}}
      <br/>
  </a>
</ng-container>
