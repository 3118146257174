import find from 'lodash-es/find';
import first from 'lodash-es/first';
import flatten from 'lodash-es/flatten';
import last from 'lodash-es/last';
import { addDate, diffDate, parseIso } from 'ts-date/esm';
import { NrtModel } from '../../../model/nrt-model';
import { extractPrices, HACK_DATE } from './price.to.task.converter';

export function dateParser(nrt: NrtModel): (date: string) => Date | null {
  const dates = extractAllDates(nrt).sort();
  const lastDefinedDate = last(dates.filter(date => date !== undefined));
  const daysBetweenDates = daysBetweenDate(nrt);

  // poor man's pattern matching
  const rules: [boolean, number][] = [
    [daysBetweenDates < 1, 1],
    [daysBetweenDates < 15, 15],
    [daysBetweenDates < 30, 30],
    [daysBetweenDates < 365, 365],
    [true, daysBetweenDates]
  ];
  const [, value] = find(rules, possibility => possibility[0] === true) || [true, 0];
  return date => parseDate(value, lastDefinedDate, date);
}

export function daysBetweenDate(nrt: NrtModel): number {
  const dates = extractAllDates(nrt).sort();
  const lastDefinedDate = last(dates.filter(date => date !== undefined));
  const firstDate = first(dates);
  if (!lastDefinedDate || !firstDate) {
    throw new Error(`Something's wrong with dates`);
  }
  return diffDate(safeParse(lastDefinedDate), safeParse(firstDate)) || 0;
}

function parseDate(daysToAddIfUndefined: number, biggestDate: string, date: string): Date | null {
  return date === HACK_DATE ? addDate(safeParse(biggestDate), daysToAddIfUndefined) : safeParse(date);
}

export function extractAllDates(nrt: NrtModel): (string | undefined)[] {
  const inputDates = flatten(nrt.input.season_sales.map(extractDate)
  .concat(nrt.input.product_process_units.flatMap(ipu => extractPrices(ipu).map(extractDate))));
  const outputDates = nrt.output.flatMap(eo => eo.price_timeline.prices).flatMap(extractDate);
  return inputDates.concat(outputDates);
}

function extractDate(object: { start_date: string, end_date?: string }): [string, string | undefined] {
  return [object.start_date, object.end_date];
}

export function safeParse(date: string): Date {
  const parsed = parseIso(date);
  if (!parsed) {
    // hack for date like `2019-07-31T13:22:36.072259`
    const retryParsed = parseIso(date.split('.')[0]);
    if (retryParsed) {
      return retryParsed;
    }
    throw new Error(`Can't parse ${date}`);
  }
  return parsed;
}
