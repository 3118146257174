import capitalize from 'lodash-es/capitalize';
import { UnaryOperator } from './object';

export type nullString = string | undefined;

// "DAY_TO_DAY" => "Day To Day"
export function beautify(text: string): string {
  return text.split('_').map(capitalize).join(' ');
}

export function safeTransform(value: nullString, transform: UnaryOperator<string>): nullString {
  return value == null ? undefined : transform(value);
}

export function bold(prefix: string, value?: any): nullString {
  return value == null ? undefined : `<b>${prefix}:</b> ${value}`;
}

export function htmlJoin(...values: nullString[]): string {
  return values.filter(value => value != null).join('</br>');
}
