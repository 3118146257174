<div #dropZone class='dropZone'></div>
<app-navbar></app-navbar>

<p-toast position='top-center'></p-toast>
<div class='content'>
<ng-container *ngIf='timelines$ | async as timelines'>
  <p-card *ngIf='!isEmpty(timelines) && ((notFound$ | async) === false) '>
    <ng-container>
      <app-action-bar></app-action-bar>
    </ng-container>
    <p-paginator (onPageChange)='paginate($event.page)' *ngIf='timelines.nrtModels.length > 1'
                 [first]='timelines.displayedModel'
                 [rows]='1'
                 [totalRecords]='timelines.nrtModels.length'></p-paginator>
    <h2> {{(timelines | currentModel).title || ''}}
      <i *ngIf='!!(timelines | currentModel).comment' [escape]='false' class='pi pi-question-circle'
         pTooltip='{{(timelines | currentModel).comment | basicHtml}}'
         tooltipPosition='bottom'></i>
    </h2>
    <div>
      <app-gantt [timelinesToDisplay]='timelines'></app-gantt>
    </div>
  </p-card>
</ng-container>
</div>
<app-scroll-to-top></app-scroll-to-top>
<app-footer></app-footer>