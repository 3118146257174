import datetimeDifference from 'datetime-difference';
import { sortBy, uniqWith } from 'lodash-es';
import toMaterialStyle from 'material-color-hash';
import { EntityId, isEntityIdentifier, toEntityIdString } from 'src/app/model/entity-id';
import { PartnerId } from 'src/app/model/partner-id';
import { format, parseIso } from 'ts-date';
import { EngineOutput } from '../../../model/engine-output';
import { EntityIdentifier } from '../../../model/entity-identifier';
import { PriceTask } from '../../../model/gantt/price-task';
import { GeoProcessUnit } from '../../../model/geo-process-unit';
import { NrtModel } from '../../../model/nrt-model';
import { Price } from '../../../model/price';
import { PriceLog } from '../../../model/price-log';
import { ProductProcessUnit } from '../../../model/product-process-unit';
import { SeasonSales } from '../../../model/season-sales';
import { clone, isEmpty, multimap, multipleSortBy, uniqueId, valueIf } from '../../../util/object';
import { beautify, bold, htmlJoin, safeTransform } from '../../../util/string';
import { gantt } from 'dhtmlx-gantt';

// The ID passed to the Gantt must be an unique number. A unique ID generator is being called in order to generate most IDs.
// Some IDs must be fixed in advance. The unique ID generator generates only natural integer so we can
// use floating points fixed value and be sure that they never clashes.
const INPUT_ID = 0.1;
const OUTPUT_ID = 0.2;
const SEASON_SALES_ID = 0.3;
const PADDING_OUTPUT = 0.4;
const CONTRAINTS_ID = 0.5;
const RANGE_CONTRAINTS_ID = 0.6;

// DHTMLX doesn't call the parser if the date is undefined, it auto adds one day to the start date, this hack fills it
// with a placeholder value so that we can replace it with a """correct""" dummy value
export const HACK_DATE = 'HACK_DATE';

function hackEndDate(task: PriceTask): PriceTask {
  if (task.end_date === undefined) {
    task.end_date = HACK_DATE;
  }
  return task;
}

export function convert(nrt: NrtModel): PriceTask[] {
  const input = clone(nrt.input);
  const output = clone(nrt.output);
  const uniqueItems: ProductProcessUnit[] =
    uniqWith(clone(nrt.input.product_process_units), (elm1, elm2) => similarPrices(elm1.prices_to_save, elm2.prices_to_save));
  input.product_process_units = sortBy(input.product_process_units,
    (ppu: ProductProcessUnit) => toEntityIdString(ppu.entity_identifier)
  );

  const logTuples: [string, PriceLog][] = output.flatMap(eo => (eo.logs || [])
    .map(log => [stringify(eo.price_timeline.entity_identifier), log] as [string, PriceLog]));

  const logMap = multimap(logTuples, tuple => tuple[0], tuple => tuple[1]);

  const inputTasks =
    input.product_process_units
    .flatMap(ipu => extractPrices(ipu)
    .map(price => mapPrice(price, ipu.entity_identifier, true, logMap[toEntityIdString(ipu.entity_identifier)])));
  if (inputTasks.length === 0) {
    return [];
  }

  const ipuTasks = input.product_process_units
  .map(ipu => mapItemProcessUnit(ipu.entity_identifier, true, INPUT_ID, ipu,
    uniqueItems.filter(elm => JSON.stringify(elm.entity_identifier) === JSON.stringify(ipu.entity_identifier)).length > 0));

  const prices = priceRequestedTask();

  const sales = seasonSales(input.season_sales, input.parameters?.number_days_retention_period);

  let constraints= [];
  if (input.product_process_units[0].item_constraints.length >=1 ) {
    constraints = constraintsManager(input.product_process_units);
  }

  let rangeConstraints= [];
  if (input.product_process_units[0].ranges_constraints.length >=1 ) {
    rangeConstraints = rangesManager(input.product_process_units);
  }

  const uniqueOutput =
    uniqWith(clone(output), (elm1, elm2) => similarPrices(elm1.price_timeline.prices, elm2.price_timeline.prices));

  const priceCalculatedByMasteprice = priceCalculatedTasks(output, uniqueOutput);

  const past = pastPriceTimeline(input);

  return [prices].concat(...past, ipuTasks, inputTasks, sales, constraints, rangeConstraints, priceCalculatedByMasteprice).map(hackEndDate);
}

export function extractPrices(ipu: ProductProcessUnit): Price[] {
  return multipleSortBy(ipu.prices_to_save, price => price.emission_date, price => price.reception_date);
}

// padding is a hack to have different ID for input/output itemCode
function mapItemProcessUnit(entityId: EntityId, isInput: boolean, parent = INPUT_ID,
                            ipu?: ProductProcessUnit, open: boolean = false): PriceTask {
  const itemInformation = ipu?.item_information ?? {};
  const taxes = ipu?.taxes ?? {};
  const tooltip = htmlJoin(
    bold('Life stage', itemInformation.life_stage),
    bold('Entity typology', safeTransform(itemInformation.entity_typology, beautify)),
    bold('Sale at loss', ipu?.sale_at_loss),
    bold('Cession price', ipu?.cession_price),
    ...Object.keys(taxes).map(tax => bold(tax, taxes[tax]))
  );

  return {
    id: itemProcessUnitId(entityId, isInput),
    text: toEntityIdString(entityId),
    type: gantt.config.types.project,
    open,
    parent,
    tooltip
  };
}

function priceRequestedTask(): PriceTask {
  return {
    id: INPUT_ID,
    text: '<b style=\"position: absolute;\"">The prices requested by SAP, CIS, ...</b>',
    color: 'white',
    type: gantt.config.types.project,
    open: false,
    parent: 0
  };
}


function previousPricesBlock(parent: number) : PriceTask {
  return {
    id: uniqueId(),
    text: 'Previous prices',
    type: gantt.config.types.project,
    open: false,
    parent: parent
  }
}

function priceCalculatedTasks(engineOutputs: EngineOutput[], uniqueOutput: EngineOutput[] = []): PriceTask[] {
  if (isEmpty(engineOutputs)) {
    return [];
  }
  const outputParent: PriceTask = {
    id: OUTPUT_ID,
    text: '<b style=\"position: absolute;\">The prices calculated by Masterprice</b>',
    type: gantt.config.types.project,
    open: true,
    parent: 0
  };
  const tasks = sortBy(engineOutputs, eo => eo.price_timeline.entity_identifier.code)
  .flatMap(output => {
    const entityId = output.price_timeline.entity_identifier;
    const timelineParent = mapItemProcessUnit(entityId, false, OUTPUT_ID, null,
      uniqueOutput.filter(eo => JSON.stringify(eo.price_timeline.entity_identifier) === JSON.stringify(entityId)).length > 0
    );

    const totalPrices = output.price_timeline.prices.flatMap(price => mapPrice(price, entityId, false, output.logs));
    const prices = totalPrices.slice(-10);

    let resultArray = [timelineParent];

    if (totalPrices.length > 10) {
      const previousPriceBlock = previousPricesBlock(timelineParent.id);

      let previousPrice = totalPrices.slice(0, totalPrices.length - 10);
      previousPrice = previousPrice.map(objet => {
        return { ...objet, parent: previousPriceBlock.id };
      });
      resultArray = resultArray.concat([previousPriceBlock, ...previousPrice]);
    }

    const candidates = (output.price_timeline.candidates || []).flatMap(price => mapPrice(price, entityId, false))
    .map(task => {
      task.id = task.id + 32;
      task.text = '??? ' + task.text + ' ???';
      return task;
    });

    return resultArray.concat(prices).concat(candidates);
  });


  return [outputParent].concat(tasks);
}

function constraintsManager(processUnit: Array<ProductProcessUnit>) {

  let constraints: PriceTask[] = [];
  let groupConstraint = [];
  for (const pu of processUnit) {
    const entityId = pu.entity_identifier;

    const timelineParent: PriceTask = {
      id: CONTRAINTS_ID + itemProcessUnitId(entityId, false),
      text: toEntityIdString(entityId),
      type: gantt.config.types.project,
      open: false,
      parent: CONTRAINTS_ID
    };

    constraints.push(...pu.item_constraints.map(c => ({
      id: c.id_item_constraint,
      text: '<p style="font-size: 10px;">' +  c.item_constraint_type + '</p>',
      parent: CONTRAINTS_ID + itemProcessUnitId(entityId, false),
      start_date: c.start_date,
      start_date_text: parseDateForHuman(c.start_date),
      end_date: c.end_date,
      end_date_text: parseDateForHuman(c.end_date),
      user_id: shorten(c.created_by, 3),
      price: {}
    } as PriceTask)));

    groupConstraint.push(timelineParent, ...constraints);
  }

  const parentConstraints: PriceTask = {
    id: CONTRAINTS_ID,
    text: `<b style="position: absolute; color: ${groupConstraint.length > 0 ? 'red' : 'black'};">Items constraints</b>`,
    type: gantt.config.types.project,
    open: false,
    parent: 0
  };

  return [parentConstraints, ...groupConstraint];
}

function rangesManager(processUnit: Array<ProductProcessUnit>) {

  let rangeConstraints: PriceTask[] = [];
  let groupConstraint = [];
  for (const pu of processUnit) {
    const entityId = pu.entity_identifier;

    const timelineParent: PriceTask = {
      id: RANGE_CONTRAINTS_ID + itemProcessUnitId(entityId, false),
      text: toEntityIdString(entityId),
      type: gantt.config.types.project,
      open: false,
      parent: RANGE_CONTRAINTS_ID
    };

    rangeConstraints.push(...pu.ranges_constraints.map(
      r => ({
        id: r.id_range_constraint,
        text: '<p>' +  r.min + ' - ' + r.max + '</p>',
        parent: RANGE_CONTRAINTS_ID + itemProcessUnitId(entityId, false),
        start_date: r.start_date,
        start_date_text: parseDateForHuman(r.start_date),
        end_date: r.end_date,
        end_date_text: parseDateForHuman(r.end_date),
        user_id: r.user,
        price: {},
        tooltip: htmlJoin(
          bold('Max value', r.max),
          bold('Min value', r.min),
          bold('Information', r.comment),
          bold('User', r.user),
          bold('Start date', r.start_date),
          bold('End date', r.end_date),
          bold('Duration', duration(r.start_date, r.end_date)),
          bold('Update date', r.update_date)
        )
      } as PriceTask))
    )

    groupConstraint.push(timelineParent, ...rangeConstraints);
  }

  const parentConstraints: PriceTask = {
    id: RANGE_CONTRAINTS_ID,
    text: `<b style="position: absolute; color: ${groupConstraint.length > 0 ? 'red' : 'black'};">Range constraints</b>`,
    type: gantt.config.types.project,
    open: false,
    parent: 0
  };

  return [parentConstraints, ...groupConstraint];
}

function seasonSales(seasons: SeasonSales[], numberDaysRetentionPeriod: number): PriceTask[] {
  if (isEmpty(seasons)) {
    return [];
  }
  const parentSeasonSales: PriceTask = {
    id: SEASON_SALES_ID,
    text: '<b style=\"position: absolute;\"">Season sales period, current retention period : '
      + numberDaysRetentionPeriod
      +  (numberDaysRetentionPeriod > 1 ? ' days</b>' : ' day</b>'),
    type: gantt.config.types.project,
    open: false,
    parent: 0
  };
  return [parentSeasonSales].concat(seasons.map(s => ({
    id: uniqueId(),
    text: ' ',
    parent: SEASON_SALES_ID,
    start_date: s.start_date,
    start_date_text: parseDateForHuman(s.start_date),
    end_date: s.end_date,
    end_date_text: parseDateForHuman(s.end_date),
    price: {}
  } as PriceTask)));
}

function pastPriceTimeline(input: GeoProcessUnit): PriceTask[] {
  return input.product_process_units
  .filter(ipu => ipu.past_price_timeline != null && ipu.past_price_timeline.length !== 0)
  .flatMap(ipu => {
    const priceTask = pastPriceTimelineBlock(ipu);
    return [priceTask].concat(...ipu.past_price_timeline.map(price => mapPrice(price, ipu.entity_identifier, true))
    .map(task => ({ ...task, parent: priceTask.id })));
  });
}

function pastPriceTimelineBlock(input: ProductProcessUnit): PriceTask {
  return {
    id: uniqueId(),
    text: 'Past price timeline',
    type: gantt.config.types.project,
    open: false,
    parent: itemProcessUnitId(input.entity_identifier, true)
  };
}

function itemProcessUnitId(entityId: EntityId | number, isInput = true): number {
  if (typeof entityId === 'number' || isEntityIdentifier(entityId)) {
    const code = (typeof entityId === 'number') ? entityId : (entityId as (EntityIdentifier)).code;
    return -(code + (isInput ? 0 : PADDING_OUTPUT));
  } else {
    return parseInt((entityId as PartnerId).partner_id, 36) + (isInput ? 0 : PADDING_OUTPUT);
  }
}

function duration(start: string, end?: string) {
  if (end == undefined) {
    return 'infinite';
  }
  const result = datetimeDifference(parseIso(start), parseIso(end));
  return Object.keys(result).filter(k => !!result[k]).map(k => `${result[k]} ${k}`).join(', ');
}

function mapPrice(price: Price, entityId: EntityId | number, isInput: boolean, logs = new Array<PriceLog>()): PriceTask {
  var filteredLogs = filterPriceLogs(price, logs);

  const tooltip = htmlJoin(
    bold('Information', exportInformation(price, filteredLogs)),
    bold('Type', beautify(price.price_type)),
    bold('Start date', price.start_date),
    bold('End date', price.end_date),
    bold('Duration', duration(price.start_date, price.end_date)),
    bold('Emission date', price.emission_date),
    bold('Reception date', price.reception_date),
    bold('Value without taxes', price.value_without_taxes),
    bold('Reference value without taxes', price.reference_price_without_taxes),
    bold('Margin', price.margin),
    bold('Original price', price.original_price?.value),
    bold('Entity scope', price.scope?.entity_type),
    bold('Geo scope', price.scope?.geographic_scope),
    bold('Price Origin', price.price_origin_type),
  );

  return {
    id: uniqueId(),
    color: toMaterialStyle(price.value + '', 700).backgroundColor,
    start_date: price.start_date,
    end_date: price.end_date,
    start_date_text: parseDateForHuman(price.start_date),
    end_date_text: parseDateForHuman(price.end_date),
    user_id: shorten(price.user_id, 3),
    text: `${prettyPrint(price)}`,
    price,
    parent: itemProcessUnitId(entityId, isInput),
    tooltip,
    icon: exportIcon(price)
  };
}

function filterPriceLogs(price: Price, pricesLogs: PriceLog[]):PriceLog[] {
  // The best thing to do should be filter by id, but we don't have this information for the moment.
  return pricesLogs?.filter(log => log.on.start_date === price.start_date && log.on.end_date === price.end_date);
}

function exportInformation(price: Price, priceLogs: PriceLog[]): string {
  if(price.original_price?.value){
    // If we have an associate log
    if(priceLogs.length != 0){
      return '⚠️'+priceLogs.map(log => log.because).join('');
    }

    // This part must be change once the log system is ok
    // Repricing
    if(price.value > price.original_price.value){ // UP
      return '⚠️'
      + ' Repricing has been carried out upwards from '
      + addCurrency(price.original_price?.value, price.currency)
      + ' to '
      + addCurrency(price.value, price.currency);
    }
    else if (price.value < price.original_price.value){ // DOWN
      return '⚠️'
        + ' Repricing has been carried out downwards from '
        + addCurrency(price.original_price?.value, price.currency)
        + ' to '
        + addCurrency(price.value, price.currency);
    }

    // If no price log but price changed
    return '⚠️'
      + ' Repricing has been done from '
      + addCurrency(price.original_price?.value, price.currency)
      + ' to '
      + addCurrency(price.value, price.currency)
      + ' . <br>';
  }
  return null;
}

function exportIcon(price: Price): string {
  if(price.original_price?.value){

   if(price.value > price.original_price.value){
      // Repricing up
      return '<div class=\'gantt_tree_icon gantt_tree_big_icon vtmx-arrow-right-up-line\'></div>'
    }
    else if (price.value < price.original_price.value){
     return '<div class=\'gantt_tree_icon gantt_tree_big_icon vtmx-arrow-right-down-line\'></div>'
    }

    return '<div class=\'gantt_tree_icon warning gantt_tree_big_icon vtmx-error-warning-line\'></div>'
  }
}

export function prettyPrint(price: Price) {
  const refPrice = price.reference_price == null ? '' : `<del>${addCurrency(price.reference_price, price.currency)}</del> `;

  return `${refPrice}${addCurrency(price.value, price.currency)} ${beautify(price.price_type)}`;
}

function addCurrency(value: number, currency: string) {
  return currency == null ? value : (value).toLocaleString('fr-FR', {
    style: 'currency',
    currency
  });
}

function parseDateForHuman(date?: string): string {
  return format(parseIso(date), 'DD-MM-YYYY') || '';
}

function shorten(s:string, maxLength:number): string{
  if(s!=null){
    return s.slice(0,maxLength);
  }
  return '';
}
function stringify(entityId: EntityIdentifier): string {
  return entityId.type + entityId.code;
}

function similarPrices(prices: Price[], prices2: Price[]): boolean {
  return JSON.stringify(prices.map(comparablePrice)) == JSON.stringify(prices2.map(comparablePrice));
}

function comparablePrice(price: Price): Price {
  return {
    id: price.id,
    value: price.value,
    start_date: price.start_date,
    price_type: price.price_type,
    currency: price.currency,
    reference_price: price.reference_price,
    end_date: price.end_date
  };
}
