import intersection from 'lodash-es/intersection';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import { LogicalOperator } from '../util/logic/logical-operator';

export class User {
  login: string;
  roles: string[];
  description: string;
  permissions: string[];

  constructor(private user: User) {
    this.login = user.login;
    this.roles = user.roles;
    this.description = user.description;
    this.permissions = user.permissions;
  }

  public checkPermissions(permissionsToCheck: string[], operator: LogicalOperator): boolean {
    if(this.permissions === undefined || permissionsToCheck == undefined){
      return false;
    }

    const permissionIntersection = intersection(this.permissions, permissionsToCheck);
    return operator === 'OR' ? !isEmpty(permissionIntersection) : isEqual(permissionIntersection, permissionsToCheck);
  }
}


