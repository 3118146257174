<nav class='navbar navbar-expand-lg navbar-light bg-light'>
  <span [ngClass]=" !isProd ? 'preprod' : '' ">
    <a class='navbar-brand' href='/'>Masterprice {{ !isProd ? "PREPROD" : "" }}</a>
    Worldwide selling price calculation
  </span>

  <ul class='navbar-nav mr-auto'>
  </ul>
  <button class="p-button-raised p-button-info mr-2 button-to-upload" pButton
          pRipple type='button' routerLink="manage"
          *appUserCan="['RETAIL_READ_OWN_REQUESTED_PRICES', 'RETAIL_READ_ALL_REQUESTED_PRICES_SO', 'RETAIL_READ_ALL_REQUESTED_PRICES_STORE', 'RETAIL_READ_ALL_REQUESTED_PRICES_ECOM']; operation 'OR'">Manage prices</button>
  <button class="p-button-raised p-button-info mr-2 button-to-upload" pButton
          pRipple type='button' routerLink="download"
          *appUserCan="['EXPORT_PRICES']; operation 'OR'">Download prices</button>
  <button class="p-button-raised p-button-info mr-2 button-to-upload" pButton
          pRipple type='button' routerLink="upload"
          *appUserCan="['SAVE_MULTIPLE_PRICE_USING_SUPPORT_UI']; operation 'OR'">Upload prices</button>

  <app-engine-picker></app-engine-picker>
  <ng-container [ngSwitch]='selectedEngine$ | async'>
    <ng-container *ngSwitchCase="'Retail'">
      <app-entity-picker-type [typeAvailable]="retailTypeAvailable"></app-entity-picker-type>
      <app-entity-picker-retail></app-entity-picker-retail>
    </ng-container>
    <ng-container *ngSwitchCase="'Marketplace'">
      <app-entity-picker-type [typeAvailable]="marketplaceTypeAvailable"></app-entity-picker-type>
      <app-entity-picker-marketplace *ngIf="(selectedType$ | async) === partnerId"></app-entity-picker-marketplace>
      <app-entity-picker-sku *ngIf="(selectedType$ | async) === sku"></app-entity-picker-sku>
      <app-entity-picker-price-id *ngIf="(selectedType$ | async) === sku && (selectedPriceIds$ | async).length > 0">
      </app-entity-picker-price-id>
    </ng-container>
    <ng-container *ngSwitchCase="'SecondLife'">
      <app-entity-picker-second-life></app-entity-picker-second-life>
    </ng-container>
  </ng-container>
  <app-geo-picker></app-geo-picker>

  <ng-container *ngIf="canDisplayMenu; else disabledButton">
    <button #menuButton pButton type="button" icon="pi pi-cog"></button>
    <app-settings-menu [element]="menuButton" [isLeftClickable]="true" ></app-settings-menu>
  </ng-container>
  <ng-template #disabledButton>
    <div pTooltip="Enter data so you can access the settings">
      <button disabled pButton type="button" icon="pi pi-cog" ></button>
    </div>
  </ng-template>

</nav>
