import { Engine } from '../model/engine';
import { Type } from '../model/type';
import { EntityId } from '../model/entity-id';
import { GeographicArea } from '../model/geographic-area';
import { NrtModel } from '../model/nrt-model';
import { User } from '../model/user';
import { QueryContext } from './model/entity-geo-area';
import { timelines, TimelinesToDisplay } from './model/timelines-to-display';
import { GeoParametersCategories } from '../model/parameters';

export class EntityIdSelect {
  static readonly type = '[Entity Id] select';

  constructor(public entityId: EntityId) {
  }
}

export class EntityIdNotFound {
  static readonly type = '[Entity Id] not found';

  constructor(public found: boolean) {
  }
}

export class EngineSelect {
  static readonly type = '[Engine] select';

  constructor(public engine: Engine) {
  }
}

export class TypeSelect {
  static readonly type = '[Type] select';

  constructor(public type: Type) {
  }
}

export class UserSelect {
  static readonly type = '[User] select';

  constructor(public user: User) {
  }
}

export class GeoAreaSelect {
  static readonly type = '[GeoArea] select';

  constructor(public geoArea: GeographicArea) {
  }
}

export class EntityGeoAreaSelect {
  static readonly type = '[Entity Id && GeoArea] select';

  constructor(public entityGeoArea: QueryContext) {
  }
}
export class ParametersDefined {
  static readonly type = '[Parameters] defined';

  constructor(public parameters: GeoParametersCategories) {
  }
}


export class ResetParameters {
  static readonly type = '[Parameters] reset';

  constructor() {}
}


// Mainly used by the drag and drop mechanism
export class TimelinesToDisplaySelect {
  static readonly type = '[Timelines] select';

  constructor(public timelines: TimelinesToDisplay) {
  }
}

export class TimelinesToDisplayReset {
  static readonly type = '[Timelines] reset';

  constructor() {
  }
}

export class TimelinesToDisplayPaginate {
  static readonly type = '[Timelines] pagination';

  constructor(public wantedPage: number) {
  }
}


export class TimelinesRecomputeModel {
  static readonly type = '[Timelines] Recompute model';

  constructor() {}
}

export function selectTimelines(nrtModels: NrtModel[]) {
  return new TimelinesToDisplaySelect(timelines(nrtModels));
}

export class GeoAreaSearch {
  static readonly type = '[GeoArea] search';

  constructor(public query: string) {
  }
}

export class GeoAreaFindFirst {
  static readonly type = '[GeoArea] find first';

  constructor(public query: string) {
  }
}

export class SkuSelect {
  static readonly type = '[Sku] Select';

  constructor(public sku: string) {
  }
}

export class PriceIdsSelect {
  static readonly type = '[Price Id] Search';

  constructor(public priceIds: string[]) {
  }
}

export class PriceIdSelect {
  static readonly type = '[Price Id] Select';

  constructor(public priceId: string) {
  }
}

export class ChangeDisplayJSON {
  static readonly type = '[jsonDisplay] Change json display';

  constructor() {}
}

export class ChangeDisplayExplain {
  static readonly type = '[explainDisplay] Change explain display';

  constructor() {}
}

export class ResetDisplayExplain {
  static readonly type = '[explainDisplay] Reset explain display';

  constructor() {}
}
