import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SkuState } from '../../state/action/state';
import { SkuSelect } from '../../state/actions';
import { isObject } from 'lodash';

@Component({
  selector: 'app-entity-picker-sku',
  templateUrl: './entity-picker-sku.component.html',
})
export class EntityPickerSkuComponent implements OnInit, OnDestroy {

  @Select(SkuState) state$: Observable<string>;
  subscription?: Subscription;
  displayedCode = '';

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.subscription = this.state$.subscribe(text => {
      if (isObject(text)) {
        this.displayedCode = '';
      } else {
        this.displayedCode = text;
      }
    });
  }

  inputChanged(text: string) {
    if (text) {
      this.store.dispatch(new SkuSelect(text));
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
