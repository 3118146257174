<footer class='text-center text-lg-start bg-light text-muted'
        xmlns='http://www.w3.org/1999/html'>
  <div class='container sm'>
    <section class='mb-1 m-1'>
        <a class='btn btn-link btn-floating text-dark s-1'
           href='https://docs.google.com/presentation/d/1iAeYGVH3miJZC3ebL2iRGbbGqawiVohB6j8RjQrQSVc/edit#slide=id.g1929efc97b8_0_0'
           target='_blank' rel='noopener noreferrer'
           role='button'>
          <i class='pi pi-book'></i>
          Training
        </a>
        <a class='btn btn-link btn-floating text-dark m-1'
           href='https://support.decathlon.net/saw/ess/offeringPage/65966346' target='_blank' rel='noopener noreferrer'
           role='button'>
          <i class='pi pi-ticket'></i>
          Offering
        </a>

      <a class='btn btn-link btn-floating text-dark m-1'
           href='mailto:masterprice_technical@decathlon.net'
           role='button'>
          <i class='pi pi-telegram'></i>
          Mail
        </a>

        <a class='btn btn-link btn-floating text-dark m-1'
           href='https://docapi.decathlon.net/masterprice/index.html' target='_blank' rel='noopener noreferrer'
           role='button'><i class='pi pi-database'></i>
          Docapi
        </a>
    </section>
  </div>
</footer>
