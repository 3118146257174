import {Component, OnDestroy, OnInit} from '@angular/core';
import { Select } from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import { Engine } from 'src/app/model/engine';
import { Type } from 'src/app/model/type';
import {takeUntil} from 'rxjs/operators';
import { EngineState, PricesIdsState, TypeState } from 'src/app/state/action/state';
import { GeoParametersCategories } from 'src/app/model/parameters';
import { ParametersState } from 'src/app/state/action/state';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Select(EngineState) selectedEngine$: Observable<Engine>;
  @Select(TypeState) selectedType$: Observable<Type>;
  @Select(ParametersState) parameters$: Observable<GeoParametersCategories>;
  
  @Select(PricesIdsState) selectedPriceIds$: Observable<string[]>;
  retailTypeAvailable: Type[] = [Type.Model, Type.Item];
  marketplaceTypeAvailable: Type[] = [Type.PartnerId, Type.Sku];
  partnerId: Type = Type.PartnerId;
  sku: Type = Type.Sku;

  destroy$: Subject<void>;
  isProd: Boolean = true;
  canDisplayMenu = false;

  ngOnInit(): void {
    this.destroy$ = new Subject();
    this.manageDisplayMenu();
    if (window.location.origin != 'https://support-ui.masterprice.decathlon.net' && window.location.origin != 'https://masterprice-support-cnpr.dktapp.cloud') {
        this.isProd = false;
    }
  }

  private manageDisplayMenu() {
    this.parameters$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        parameters => {
          this.canDisplayMenu = !!parameters && Object.keys(parameters).length !== 0;
        });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
